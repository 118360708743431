import React from "react";
import Grid from "@mui/material/Grid";
import {
    formatterCurrency,
    GRAY_BG_UX, 
    GRAY_LABEL_UX, 
    WHITE_THEME_BLACK, 
    PAPER_PADDING_THEME, 
    RED_INFO, 
    BORDER_BUTTON,
    INFO_THEME,
    GRAY_BORDER_TABLE,
    LINE_TABLE,
    COLOR_LABEL_INPUT,
    WHITE_TABLE,
    BORDER_TABLE,
    ITENS_PAINEL,
    RED_NEGATIVE_UX
} from "../../shared/utils";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { useSelector, useDispatch } from "react-redux";
import { changeOpenAccordionSelected } from "../../actions/StoreActions";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import DataGrid from "../DataGrid/DataGrid";
import {useNavigate} from "react-router-dom";
import ReportIcon from "@mui/icons-material/Report";


const MovimentacoesConta = (props) => {
    
    const nav = useNavigate();
    const dispatch = useDispatch();

    const {bancoLogo, apelidoConta, saldoConta, detalhesData} = props;

    const isAdmin = useSelector(state => state.AuthReducer.isAdmin);
    const isOperator = useSelector(state => state.AuthReducer.isOperator);
    const isAprover = useSelector(state => state.AuthReducer.isApprover);

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const openAccordionSelected = useSelector(state => state.StoreReducer.openAccordionSelected);

    const handleChange = (panel) => (event, isExpanded) => {
        dispatch(changeOpenAccordionSelected(isExpanded ? panel : ""));
    };

    const getBackgroundConciliacao = (conciliacao) => {
        if (conciliacao === "Manual" || conciliacao === "Automático") {
            return GRAY_LABEL_UX;
        } else if (conciliacao === "Não Conciliado") {
            return RED_INFO;
        }
    };

    const columns = [
        { field: "descricao_vxmf", headerName: "Descrição", flex: 1, align: "start", headerAlign: "center" },
        { 
            field: "valor_vxmf",
            headerName: "Valor",
            flex: 1,
            align: "start",
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <div style={{borderRight: temaEscuro ? `1px solid ${INFO_THEME}` : `1px solid ${GRAY_BORDER_TABLE}`, marginRight: -10}}>
                        <span style={{color: params.row.valor_vxmf < 0 && RED_INFO, fontWeight: "bold", width: "10000px"}}>
                            {formatterCurrency(params.row.valor_vxmf, 2)}
                        </span>
                    </div>
                );
            },
        },
        { field: "descricao_banco", headerName: "Descrição", flex: 1, align: "start", headerAlign: "center" },
        { 
            field: "valor_banco",
            headerName: "Valor",
            flex: 1,
            align: "start",
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <span style={{color: params.row.valor_banco < 0 && RED_INFO, fontWeight: "bold", width: "10000px"}}>
                        {formatterCurrency(params.row.valor_banco, 2)}
                    </span>
                );
            },
        },
        {
            field: "is_conciliado",
            headerName: "Conciliação",
            flex: 1,
            align: "start",
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <span style={{backgroundColor: getBackgroundConciliacao(params.row.is_conciliado), padding: 8, borderRadius: "3px", color: temaEscuro ? LINE_TABLE : WHITE_TABLE}}>
                        {params.row.is_conciliado}
                    </span>
                );
            }
        },
    ];

    const columnGroupingModel = [
        {
            groupId: "Lançado no VXMF",
            children: [{ field: "descricao_vxmf" }, { field: "valor_vxmf" }],
            renderHeaderGroup: (params) => (
                <div style={{display: "flex", alignItems: "center", justifyContent: "center", width: "100%"}}>
                    <span style={{color: temaEscuro ? BORDER_BUTTON : GRAY_BG_UX, fontWeight: "bold"}}>{params.groupId}</span>
                </div>
            )
        },
        {
            groupId: "Movimentado no banco",
            children: [{ field: "descricao_banco" }, { field: "valor_banco" }, { field: "conciliacao" }],
            renderHeaderGroup: (params) => (
                <div style={{display: "flex", alignItems: "center", justifyContent: "center", width: "100%"}}>
                    <span style={{color: temaEscuro ? BORDER_BUTTON : GRAY_BG_UX, fontWeight: "bold"}}>{params.groupId}</span>
                </div>
            )
        },
    ];

    const handleOpenModalEditMovimentacao = (row) => {

        const routeMap = {
            "Transferência": "/editar_transferencia",
            "Despesa": "/editar_despesa",
            "Receita": "/editar_despesa",
        };

        const route = routeMap[row.tipo_movimentacao];

        if (route) {
            nav(route, { state: { id_movimentacao: row.id } });
        }

    };

    const handleEditMovimentacao = (row) => {
        if (isAdmin || isOperator || isAprover) {
            handleOpenModalEditMovimentacao(row);
        }
    };

    return (
        <React.Fragment>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <Grid container spacing={2}>
                        <Grid item>
                            <img src={bancoLogo} />
                        </Grid>
                        <Grid item>
                            <Grid container>
                                <Grid item xs={12}>
                                    <a style={{fontSize: "18px", fontWeight: "bold"}}>{apelidoConta}</a>
                                </Grid>
                                <Grid item xs={12}>
                                    <a style={{fontSize: "12px"}}>Saldo atual no Menu financeiro: {formatterCurrency(saldoConta)}</a>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {/*<Grid item xs={6} sx={{display: "flex", justifyContent: "end"}}>*/}
                {/*    <GrayButton title={"Acessar fluxo de caixa"} />*/}
                {/*</Grid>*/}
                <Grid item xs={12}>
                    <Grid container sx={{display: "flex", mb: 2}}>
                        <Grid item sx={{display: "flex", alignItems: "center", gap: .5}}>
                            <ReportIcon
                                sx={{color: RED_NEGATIVE_UX}}
                                fontSize="small"
                            />
                            <span style={{fontSize: "14px", margin: 0}}>
                                Divergência de saldo
                            </span>
                        </Grid>
                    </Grid>
                    <React.Fragment>
                        {detalhesData.map((item) => (
                            <Accordion
                                key={item.data}
                                expanded={openAccordionSelected === item.data}
                                onChange={handleChange(item.data)}
                                sx={{ marginBottom: 1, backgroundColor: "transparent" }}
                                data-testid="movimentacao-accordion"
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <React.Fragment>
                                            {
                                                item.ultimo_saldo !== item.saldo_banco && (
                                                    <ReportIcon
                                                        sx={{color: RED_NEGATIVE_UX}}
                                                    />
                                                )
                                            }
                                            <ExpandMoreIcon style={{color: !temaEscuro && GRAY_BG_UX }} />
                                        </React.Fragment>
                                    }
                                    sx={{ backgroundColor: GRAY_LABEL_UX }}
                                >
                                    <Grid container sx={{ display: "flex", justifyContent: "space-between" }}>
                                        <Grid item xs={4} sx={{display: "flex", justifyContent: "start", alignItems: "center", gap: 1}}>
                                            <Typography sx={{color: temaEscuro ? WHITE_THEME_BLACK : GRAY_BG_UX}}>{item.data}</Typography>
                                            <Typography sx={{color: temaEscuro ? COLOR_LABEL_INPUT : GRAY_BG_UX, width: "200px"}}>{item.dia_semana}</Typography>

                                        </Grid>
                                        <Grid item xs={6} sx={{display: "flex", justifyContent: "end", alignItems: "center", marginRight: 2, gap: 1}}>
                                            <Typography sx={{color: temaEscuro ? COLOR_LABEL_INPUT : GRAY_BG_UX}}>
                                                Saldo no final do dia: 
                                            </Typography>
                                            <Typography sx={{color: temaEscuro ? WHITE_THEME_BLACK : GRAY_BG_UX, fontWeight: "bold"}}>
                                                {formatterCurrency(item.total_vxmf, 2)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails
                                    sx={{
                                        padding: "16px 0px 0px 0px",
                                        backgroundColor: temaEscuro && PAPER_PADDING_THEME,
                                    }}
                                > 
                                    <div style={{maxHeight: "77vh", width: "100%"}}>
                                        {openAccordionSelected === item.data && 
                                            <DataGrid
                                                columns={columns}
                                                data={item.detalhes}
                                                row={(row) => row.id}
                                                customRowClick={(params) => {
                                                    handleEditMovimentacao(params.row);
                                                }}
                                                columnGroupingModel={columnGroupingModel}
                                                columnGroupHeaderHeight={36}
                                            />
                                        }
                                    </div>
                                    <Grid container sx={{padding: 2, marginTop: 2}}>
                                        <Grid item xs={6}>
                                            <Typography sx={{color: temaEscuro ? WHITE_THEME_BLACK : GRAY_BG_UX, fontSize: "18px", fontWeight: "bold"}}>
                                                Totais do dia
                                            </Typography>
                                            <Typography sx={{color: temaEscuro ? COLOR_LABEL_INPUT : GRAY_BG_UX, fontSize: "14px", fontWeight: "bold"}}>
                                                {item.data}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>                                    
                                            <Grid container sx={{display: "flex", alignItems: "center", justifyContent: "end"}}>
                                                <Grid item xs={4} sx={{display: "flex", alignItems: "end", flexDirection: "column"}}>
                                                    <Typography sx={{color: temaEscuro ? COLOR_LABEL_INPUT : BORDER_TABLE, fontSize: "14px"}}>
                                                        VXMF (R$)
                                                    </Typography>
                                                    <Typography sx={{color: temaEscuro ? WHITE_THEME_BLACK : ITENS_PAINEL, fontSize: "16px", fontWeight: "bold"}}>
                                                        {formatterCurrency(item.total_vxmf, 2)}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4} sx={{display: "flex", alignItems: "end", flexDirection: "column"}}>
                                                    <Typography sx={{color: temaEscuro ? COLOR_LABEL_INPUT : BORDER_TABLE, fontSize: "14px"}}>
                                                        Banco (R$)
                                                    </Typography>
                                                    <Typography sx={{color: temaEscuro ? WHITE_THEME_BLACK : ITENS_PAINEL, fontSize: "16px", fontWeight: "bold"}}>
                                                        {formatterCurrency(item.total_banco, 2)}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4} sx={{display: "flex", alignItems: "end", flexDirection: "column"}}>
                                                    <Typography sx={{color: temaEscuro ? COLOR_LABEL_INPUT : BORDER_TABLE, fontSize: "14px"}}>
                                                        Diferença (R$)
                                                    </Typography>
                                                    <Typography sx={{color: temaEscuro ? WHITE_THEME_BLACK : ITENS_PAINEL, fontSize: "16px", fontWeight: "bold"}}>
                                                        {formatterCurrency(item.total_diferenca, 2)}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </React.Fragment>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default MovimentacoesConta;