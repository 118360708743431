import React from "react";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {
    BLUE_THEME, smallPaperStyle,
    BORDER_TABLE, WHITE_ESCRITA_THEME,
    BORDER_BUTTON, GRAY_BG_UX, BORDER_PAPER
} from "../../shared/utils";
import {useSelector} from "react-redux";
import Divider from "@mui/material/Divider";
import GrayButton from "../Buttons/GrayButton";
import YellowButton from "../Buttons/YellowButton";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";


const ModalSelectedLoja = (props) => {

    const {
        openModal,
        setOpenModal,
        inputLoja,
        setInputLoja,
        handleChangeLoja,
    } = props;

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const lojas = useSelector(state => state.StoreReducer.lojas);

    return (
        <Modal
            open={openModal}
            onClose={() => setOpenModal(false)}
            data-testid="generic-modal"
        >
            <Paper style={{...smallPaperStyle, backgroundColor: temaEscuro && BLUE_THEME, width: "50%"}}>
                <Grid item xs={10} className="start">
                    <h3 style={styles.text}>Selecione uma loja</h3>
                </Grid>
                <Divider sx={{backgroundColor: temaEscuro ? BORDER_TABLE : BORDER_PAPER}}/>
                <Grid container spacing={2} className="relative">
                    <Grid item xs={12} sx={{marginBottom: 4, marginTop: 2}}>
                        <FormControl size="small" sx={{width: "100%"}}>
                            <InputLabel id="demo-simple-select-label" sx={{ color: BORDER_BUTTON, fontSize: "15px"}}>Loja</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={inputLoja}
                                label="Loja"
                                sx={{
                                    backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX,
                                    "& .MuiInputBase-input": {
                                        color: temaEscuro && WHITE_ESCRITA_THEME,
                                    },
                                }}
                                onChange={(e) => setInputLoja(e.target.value)}
                            >
                                {lojas.map((obj) => {
                                    return (
                                        <MenuItem data-testid="menu-item" key={obj} value={obj}>
                                            {obj}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container sx={{display: "flex", justifyContent: "end", gap: 2}}>
                    <Grid item>
                        <GrayButton
                            onClick={() => setOpenModal(false)}
                            title="Cancelar"/>
                    </Grid>
                    <Grid item>
                        <YellowButton
                            onClick={handleChangeLoja}
                            testId="generic-continue-btn"
                            title="Continuar"
                        />
                    </Grid>
                </Grid>
            </Paper>
        </Modal>
    );

};

export default React.memo(ModalSelectedLoja);

const styles = {
	main: {
		marginBottom: "0px",
	},
    text: {
        marginTop: 0
    }
};