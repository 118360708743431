export const cobrarSempreOptions = [
    { value: 1, label: "1° dia do mês" },
    { value: 2, label: "2° dia do mês" },
    { value: 3, label: "3° dia do mês" },
    { value: 4, label: "4° dia do mês" },
    { value: 5, label: "5° dia do mês" },
    { value: 6, label: "6° dia do mês" },
    { value: 7, label: "7° dia do mês" },
    { value: 8, label: "8° dia do mês" },
    { value: 9, label: "9° dia do mês" },
    { value: 10, label: "10° dia do mês" },
    { value: 11, label: "11° dia do mês" },
    { value: 12, label: "12° dia do mês" },
    { value: 13, label: "13° dia do mês" },
    { value: 14, label: "14° dia do mês" },
    { value: 15, label: "15° dia do mês" },
    { value: 16, label: "16° dia do mês" },
    { value: 17, label: "17° dia do mês" },
    { value: 18, label: "18° dia do mês" },
    { value: 19, label: "19° dia do mês" },
    { value: 20, label: "20° dia do mês" },
    { value: 21, label: "21° dia do mês" },
    { value: 22, label: "22° dia do mês" },
    { value: 23, label: "23° dia do mês" },
    { value: 24, label: "24° dia do mês" },
    { value: 25, label: "25° dia do mês" },
    { value: 26, label: "26° dia do mês" },
    { value: 27, label: "27° dia do mês" },
    { value: 28, label: "28° dia do mês" },
    { value: 29, label: "29° dia do mês" },
    { value: 30, label: "30° dia do mês" },
    { value: 0, label: "Último dia do mês" },
];
