import React  from "react";
import {styleText, GRAY_LABEL_UX} from "../../shared/utils";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {useSelector} from "react-redux";
import {BLUE_THEME, WHITE_ESCRITA_THEME, BORDER_TABLE, BORDER_PAPER} from "../../shared/utils";
import Divider from "@mui/material/Divider";
import FileViewer from "../FileViewer/FileViewer";


const AnexoPreviewModal = (props) => {
    const {openModal, setOpenModal, file} = props;
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);    

    return(
        <React.Fragment>
            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                data-testid="info-modal"
            >
                <Paper sx={{
                    width: file?.name.split(".").at(-1) !== "pdf" ? "auto" : "60%",
                    height: file?.name.split(".").at(-1) !== "pdf" ? "auto" : "60%",
                    overflowY: "hidden",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    bgcolor: "background.paper",
                    padding: "20px", backgroundColor: temaEscuro && BLUE_THEME, pt: 3}}>
                    <Grid item xs={10} className="start">
                        <span style={{...styleText, fontWeight: "700", color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX}}>{file?.name}</span>
                    </Grid>
                    <Divider sx={{backgroundColor: temaEscuro ? BORDER_TABLE : BORDER_PAPER, mb: 2}} />
                    {
                        file && (
                            <FileViewer
                                fileType={file.name.split(".").at(-1)}
                                filePath={URL.createObjectURL(file)}
                                fileBlob={file}
                            />
                        )
                    }
                </Paper>
            </Modal>
        </React.Fragment>
    );                  
};

export default AnexoPreviewModal;