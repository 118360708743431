import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {useSelector} from "react-redux";
import Grid from "@mui/material/Grid";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";

import {
    BLUE_THEME,
    BORDER_BUTTON,
    translateAutocomplete,
    GRAY_BG_UX,
    textCompleteStyleBlack,
    textCompleteStyle,
    YELLOW_BG_UX,
    COLOR_LABEL_INPUT
} from "../../shared/utils";


const FilterSingleOption = (props) => {
    const {filterOptions, selectedOption, setSelectedOption, setOpenCreateModal, setOptionCreateModal, inputError} = props;
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const defaultBorderRadius = 4;
    const stylePaper = {
		backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX, fontFamily: "Inter, sans-serif",
		color: temaEscuro ? "#FFF" : "#4A5568",
		fontWeight: "500"
	};

    const handleCreate = () => {
        setOpenCreateModal(true);
        if (props?.placeholder === "Fornecedor *") {
            setOptionCreateModal("fornecedor");
        } else if (props?.placeholder === "Categoria de despesa *" || props?.placeholder === "Categoria *" || props?.placeholder === "Categoria") {
            setOptionCreateModal("categoria");
        } else if (props?.placeholder === "Produto/Serviço *") {
            setOptionCreateModal("produtoServico");
        } else if (props?.placeholder === "Cliente") {
            setOptionCreateModal("cliente");
        } else if (props?.placeholder === "Centro de custo") {
            setOptionCreateModal("centroCusto");
        }
    };

    return(
        <Grid container sx={{display: "flex", flexWrap: "nowrap", width: props?.width, justifyContent: props?.justifyContent}}>
            <Grid item>
                <Autocomplete
                    noOptionsText={translateAutocomplete} // <= componente importado do arquivo utils.js
                    id="checkboxes-tags-demo"
                    size={"small"}
                    data-testid={props?.testId ? props.testId : "filter-with-tag"}
                    options={filterOptions ? filterOptions : []}
                    slotProps={{
                        paper: {style: stylePaper}
                    }}
                    fullWidth
                    style={{
                        width: props?.width || "250px",
                        ...(!props?.disabled && {backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX}),
                    }}
                    disabled={props?.disabled}
                    value={selectedOption}
                    disableClearable={props?.disableClearable}
                    onChange={(event, newValue) => setSelectedOption(newValue)}
                    renderOption={(props, option) => (
                        <li {...props} key={option?.value ?? option}>
                            {option?.label ?? option}
                        </li>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            sx={{
                                ...(temaEscuro ? textCompleteStyleBlack : textCompleteStyle),
                                "& label": { color: temaEscuro ? COLOR_LABEL_INPUT : BORDER_BUTTON }

                            }}
                            label={props?.placeholder}
                            error={inputError}
                            InputLabelProps={{
                                style: {color: temaEscuro && params.inputProps.value === "" && BORDER_BUTTON},
                            }}
                            placeholder={props?.placeholder}
                        />
                    )}
                />
            </Grid>
            {props?.canCreate && (
                <Grid item>
                    <AddCircleOutlineOutlinedIcon
                        onClick={() => handleCreate()}
                        sx={{
                            ...styles.styleArrowButton,
                            borderTopRightRadius: defaultBorderRadius,
                            borderBottomRightRadius: defaultBorderRadius,
                            backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX,
                            border: `1px solid ${temaEscuro ? "#616169" : "#C3C3C3"}`,
                        }}
                    />
                </Grid>
            )}

        </Grid>

    );
};

export default FilterSingleOption;

const styles = {
    styleArrowButton: {
        color: YELLOW_BG_UX,
        fontWeight: "600",
        boxShadow: "none",
        height: "21.5px",
        p: 1,
        cursor: "pointer",
        fontSize: "15px",
    }
};
