import React, {useEffect} from "react";
import {
    DataGridPremium,
    gridPageCountSelector,
    GridPagination,
    useGridApiContext,
    useGridApiRef,
    useGridSelector,
} from "@mui/x-data-grid-premium";
import {ptBR} from "@mui/x-data-grid-premium/locales";
import {
    BLUE_THEME,
    GRAY_STRONG_UX,
    INFO_THEME,
    LINE_TABLE,
    PERFIL_THEME,
    WHITE_ESCRITA_THEME,
    WHITE_THEME_BLACK
} from "../../shared/utils";
import {Grid} from "@mui/material";
import {useSelector} from "react-redux";
import MuiPagination from "@mui/material/Pagination";
import OptionsEXMSelectedItems from "../Buttons/OptionsEXMSelectedItems";


export default function DataGridWithServerPagination(props) {

    const {
        row,
        data,
        columns,
        pagination,
        page,
        pageSize,
        onPageChange,
        rowCount,
        isRowSelectable,
        checked,
        customRowSelectionModelChange,
        exmItems = 0,
        isEXM = false,
        getExtratoMovimentacoes,
        deleteMovimentacoesLote,
        pageSizeOptions = [0],
        setRowsSelected,
        onRowClick,
        initialState,
    } = props;

    const apiRef = useGridApiRef();
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const isAdmin = useSelector(state => state.AuthReducer.isAdmin);
    const isApprover = useSelector(state => state.AuthReducer.isApprover);
    const isOperator = useSelector(state => state.AuthReducer.isOperator);

    function Pagination({className}) {
        const apiRef = useGridApiContext();
        const pageCount = useGridSelector(apiRef, gridPageCountSelector);

        return (
            <MuiPagination
                color="primary"
                className={className}
                count={pageCount}
                page={page + 1}
                onChange={(event, newPage) => {
                    onPageChange(newPage - 1);
                }}
            />
        );
    }

    function CustomPagination(props) {

        return (
            <React.Fragment>
                <GridPagination ActionsComponent={Pagination} {...props} sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "60px",
                    width: "100%"
                }} labelDisplayedRows={({from, to, count}) => {
                    from = page * pageSize + from;
                    to = Math.min(page * pageSize + to, count);
                    return `${from}-${to} de ${count}`;
                }}/>
                {
                    (exmItems.length > 0 && (isAdmin || isOperator || isApprover)) && (
                        <Grid container sx={{width: "400px", display: "flex", justifyContent: "end", paddingRight: 1.2}}>
                            <Grid item>
                                <OptionsEXMSelectedItems exmItems={exmItems} data={data}
                                                         getExtratoMovimentacoes={getExtratoMovimentacoes}
                                                         deleteMovimentacoesLote={deleteMovimentacoesLote}/>
                            </Grid>
                        </Grid>
                    )
                }
            </React.Fragment>
        );
    }

    useEffect(() => {
        if (exmItems) {
            const selectedRows = exmItems.map(item => apiRef.current.getRow(item));
            setRowsSelected(prevRows => {
                // Array com as linhas já selecionadas
                const newRows = [...prevRows];
                // Adiciona novas linhas selecionadas
                selectedRows.forEach(row => {
                    if (!newRows.some(existingRow => existingRow.id === row.id)) {
                        newRows.push(row);
                    }
                });
                // Remove as linhas desmarcadas
                return newRows.filter(existingRow => selectedRows.some(row => row.id === existingRow.id));
            });
        }
    }, [exmItems]);


    return (
        <DataGridPremium
            sx={{
                "& .MuiDataGrid-cell.MuiDataGrid-cell--editing": {
                    backgroundColor: "transparent"
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                    fontSize: "16px",
                    fontWeight: "500",
                    color: temaEscuro ? LINE_TABLE : GRAY_STRONG_UX
                },
                "& ::-webkit-scrollbar-thumb": {
                    backgroundColor: temaEscuro ? PERFIL_THEME : WHITE_ESCRITA_THEME
                },
                "& .MuiDataGrid-row--editing .MuiDataGrid-cell": {
                    backgroundColor: "transparent"
                },
                fontSize: "14px", fontWeight: 400, borderRadius: 2, borderColor: temaEscuro && INFO_THEME
            }}
            style={{
                "--DataGrid-containerBackground": temaEscuro && BLUE_THEME,
                "--DataGrid-pinnedBackground": temaEscuro && BLUE_THEME,
                backgroundColor: temaEscuro && BLUE_THEME,
                color: temaEscuro ? WHITE_THEME_BLACK : GRAY_STRONG_UX
            }}
            rowHeight={70}
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            checkboxSelection={checked}
            onRowSelectionModelChange={customRowSelectionModelChange}
            apiRef={apiRef}
            rows={data}
            rowCount={rowCount}
            getRowId={row}
            columns={columns}
            onCellClick={(params) => {
                if (params.colDef.field != "__check__" && onRowClick) {
                    onRowClick(params);
                }
            }}
            pagination={pagination}
            paginationMode="server"
            page={page}
            pageSize={pageSize}
            onPageChange={onPageChange}
            isRowSelectable={isRowSelectable}
            disableRowSelectionOnClick={isEXM}
            rowsPerPageOptions={pageSizeOptions}
            slots={{
                pagination: CustomPagination,
            }}
            initialState={initialState}
        />
    );
}