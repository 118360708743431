import React from "react";
import {useSelector} from "react-redux";
import { Grid } from "@mui/material";
import YellowButton from "../Buttons/YellowButton";
import ErrorIcon from "@mui/icons-material/Error";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import ReplayIcon from "@mui/icons-material/Replay";
import GrayButton from "../Buttons/GrayButton";
import {customFooterStyle} from "../../shared/utils";


const BoletosFooter = ({selectedTab, handleOpenModalBoleto, setOpenModalReset, boletos}) => {

    const isOperator = useSelector(state => state.AuthReducer.isOperator);
    const isAdmin = useSelector(state => state.AuthReducer.isAdmin);
    
    return (
        boletos.length > 0 && (
            <Grid container>
                <Grid item sx={{...customFooterStyle}} xs={12} data-testid="datagrid-num-lines" >
                    {selectedTab !== "Pendente" ? (
                        <GrayButton
                            title={"Reverter"}
                            startIcon={<ReplayIcon />}
                            onClick={() => setOpenModalReset(true)}
                        />
                    ) : (
                        <React.Fragment>
                            {(isOperator || isAdmin) && (
                                <GrayButton
                                    title={"Ignorar"}
                                    startIcon={<ErrorIcon />}
                                    onClick={() => handleOpenModalBoleto("Ignorado")}
                                />
                            )}
                            <GrayButton
                                title={"Recusar"}
                                startIcon={<ClearIcon />}
                                onClick={() => handleOpenModalBoleto("Cancelado")}
                            />
                            <YellowButton
                                title={"Aceitar"}
                                startIcon={<DoneIcon />}
                                onClick={() => handleOpenModalBoleto("Aceito")}
                            />
                        </React.Fragment>
                    )}
                </Grid>
            </Grid>
        )
    );
};

export default React.memo(BoletosFooter);