import React, { useState, useEffect } from "react";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import {
    BLUE_THEME,
    BORDER_TABLE,
    GRAY_LABEL_UX,
    LINE_TABLE, paperStyle,
    ROW_MAIN,
    WHITE_ESCRITA_THEME,
    WHITE_TABLE,
    formatterPercentage
} from "../../shared/utils";
import FilterSingleOption from "../Filter/FilterSingleOption";
import InputNumber from "../Input/InputNumber";
import GrayButton from "../Buttons/GrayButton";
import InputCurrency from "../Input/InputCurrency";


const RateiosReceita = (props) => {
    const {rateios, setRateios, categoriaOptions, valorCompra, setOpenCreateModal, setOptionCreateModal} = props;
    const [totalRateio, setTotalRateio] = useState(0);
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    useEffect(() => {
        calcTotalRateio();
    }, [rateios]);

    const handleRemoveRateio = (idx) => {
        rateios.splice(idx, 1);
        setRateios([...rateios]);
    };

    const calcTotalRateio = () => {
        let total = 0;
        rateios.map(rateio => {
            total += rateio.valor_total;
        });
        return setTotalRateio(total);
    };

    const calcPercentage = () => {
        if (isNaN(valorCompra) || !isFinite(valorCompra) || valorCompra === 0) {
            return 100;
        }
        return formatterPercentage(((valorCompra - totalRateio) * 100) / valorCompra, 2);
    };

    return (
        <React.Fragment>
            <Grid container spacing={2} sx={{ml: 0.5}}>
                <Grid item xs={12}>
                    <TableContainer className={"table-container"}>
                        <Table>
                            <TableHead style={{backgroundColor: temaEscuro ? ROW_MAIN : GRAY_LABEL_UX}}>
                                <TableRow>
                                    <TableCell
                                        align={"center"}
                                        style={{
                                        ...{zIndex: 3},
                                        ...{fontWeight: "bold"},
                                        ...{ backgroundColor: temaEscuro ? ROW_MAIN : GRAY_LABEL_UX},
                                        ...{color: temaEscuro && LINE_TABLE},
                                        ...{padding: 5}}}
                                    >
                                        Categoria *
                                    </TableCell>
                                    <TableCell
                                        align={"center"}
                                        style={{
                                        ...{zIndex: 3},
                                        ...{fontWeight: "bold"},
                                        ...{ backgroundColor: temaEscuro ? ROW_MAIN : GRAY_LABEL_UX},
                                        ...{color: temaEscuro && LINE_TABLE},
                                        ...{padding: 5}}}
                                    >
                                        Valor total (R$) *
                                    </TableCell>
                                    <TableCell
                                        align={"center"}
                                        style={{
                                        ...{zIndex: 3},
                                        ...{fontWeight: "bold"},
                                        ...{ backgroundColor: temaEscuro ? ROW_MAIN : GRAY_LABEL_UX},
                                        ...{color: temaEscuro && LINE_TABLE},
                                        ...{padding: 5}}}
                                    >
                                        Porcentagem (%) *
                                    </TableCell>
                                    {
                                        rateios.length > 1 && (
                                            <TableCell/>
                                        )
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    rateios.map((rateio, idx) => {
                                        return(
                                            <TableRow key={idx} style={{backgroundColor: temaEscuro ? BLUE_THEME : WHITE_TABLE}}>
                                                <TableCell align={"center"}>
                                                    <FilterSingleOption
                                                        filterOptions={categoriaOptions}
                                                        selectedOption={rateio.categoria}
                                                        setSelectedOption={(value) => (setRateios(rateios.map((rateio_aux, idx_aux) => {
                                                            if (idx_aux === idx) {
                                                                rateio_aux.categoria = value;
                                                            }
                                                            return rateio_aux;
                                                        })))}
                                                        placeholder={"Categoria"}
                                                        canCreate
                                                        setOpenCreateModal={setOpenCreateModal}
                                                        setOptionCreateModal={setOptionCreateModal}
                                                        justifyContent={"center"}
                                                    />
                                                </TableCell>
                                                <TableCell align={"center"}>
                                                    <InputCurrency
                                                        valor={rateio.valor_total}
                                                        setValor={(value) => (setRateios(rateios.map((rateio_aux, idx_aux) => {
                                                            if (idx_aux === idx) {
                                                                rateio_aux.valor_total = parseFloat(value);
                                                            }
                                                            if (valorCompra) {
                                                                rateio_aux.porcentagem = parseFloat((rateio_aux.valor_total * 100) / valorCompra);
                                                            }
                                                            return rateio_aux;
                                                        })))}
                                                        width={"150px"}
                                                        error={rateio.valor_total === 0}
                                                        ml={"30%"}
                                                    />
                                                </TableCell>
                                                <TableCell align={"center"}>
                                                    <InputNumber
                                                        value={rateio.porcentagem}
                                                        setValue={(value) => (setRateios(rateios.map((rateio_aux, idx_aux) => {
                                                            if (idx_aux === idx) {
                                                                rateio_aux.porcentagem = parseFloat(value);
                                                            }
                                                            if (valorCompra) {
                                                                rateio_aux.valor_total = parseFloat((rateio_aux.porcentagem * valorCompra) / 100);
                                                            }
                                                            return rateio_aux;
                                                        })))}
                                                        width={"100px"}
                                                        padding={0.5}
                                                        error={rateio.porcentagem === 0}
                                                    />
                                                </TableCell>
                                                {
                                                    rateios.length > 1 && (
                                                        <TableCell
                                                            align={"center"}
                                                            style={{padding: 0, cursor: "pointer"}}
                                                            onClick={() => handleRemoveRateio(idx)}
                                                        >
                                                            <a>X</a>
                                                        </TableCell>
                                                    )
                                                }
                                            </TableRow>
                                        );
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={12}>
                    <GrayButton
                        title={"+ Adicionar categoria"}
                        size={"small"}
                        onClick={() => {
                            setRateios([...rateios, {
                                categoria: null,
                                valor_total: 0,
                                porcentagem: 0,
                                centro_custo: null,
                            }]);
                        }}
                    />
                </Grid>
                <Grid item xs={12} sx={{
                    border: 0,
                    borderBottom: temaEscuro ? `1px solid ${BORDER_TABLE}` : `1px solid ${WHITE_ESCRITA_THEME}`,
                    mb: 2.5,
                    ml: 2,
                }}/>
                <Grid item xs={12}>
                    <Box sx={{
                        ...paperStyle,
                        backgroundColor: temaEscuro && BLUE_THEME,
                        borderRadius: 2,
                    }}>
                        <Grid container spacing={2} sx={{display: "flex", justifyContent: "end"}}>
                            <Grid item>
                                <a style={{fontSize: "12px"}}>Restante do rateio</a>
                            </Grid>
                            <Grid item sx={{mr: 3}}>
                                <a style={{fontSize: "12px"}}>Total rateado</a>
                            </Grid>
                            <Grid item xs={12}/>
                            <Grid item>
                                <a style={{fontSize: "18px", fontWeight: "bold"}}>{calcPercentage()}%</a>
                            </Grid>
                            <Grid item>
                                <a style={{fontSize: "18px", fontWeight: "bold"}}>R${valorCompra - totalRateio}</a>
                            </Grid>
                            <Grid item sx={{
                                border: 0,
                                borderRight: temaEscuro ? `1px solid ${BORDER_TABLE}` : `1px solid ${WHITE_ESCRITA_THEME}`,
                                ml: 2.5,
                                mr: 2.5
                            }}/>
                            <Grid item>
                                <a style={{fontSize: "18px", fontWeight: "bold"}}>R${totalRateio}</a>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default RateiosReceita;