import React, { useState, useEffect } from "react";
import moment from "moment";
import api from "../../axios";
import Grid from "@mui/material/Grid";
import DataGrid from "../DataGrid/DataGrid";
import FilterEXM from "../Filter/FilterEXM";
import { useLocation } from "react-router-dom";
import GrayButton from "../Buttons/GrayButton";
import NotasFooter from "../Footers/NotasFooter";
import { useDispatch, useSelector } from "react-redux";
import { showSnackMessage } from "../../actions/SnackActions";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { BLUE_THEME, GRAY_BG_UX, GRAY_LABEL_UX, WHITE_TABLE, LINE_TABLE, formatterCurrency, capitalizeFirstLetter } from "../../shared/utils";
import ReprocessarNotaModal from "../Modal/ReprocessarNotaModal";



const NotasRecebidosTab = () => {
    const dispatch = useDispatch();
   const location = useLocation();

    const [periodo, setPeriodo] = useState(location.state !== null ? "custom_periodo" : "este_mes");
    const [filterPlaceholders, setFilterPlaceholders] = useState({});
    const [specificDate, setSpecificDate] = useState("");
    const [arrowCounter, setArrowCounter] = useState(0);
    const [selectedNotas, setSelectedNotas] = useState([]);
    const [notasRecebidosData, setNotasRecebidosData] = useState([]);
    const [openReprocessarNota, setOpenReprocessarNota] = useState(false);

    const isAdmin = useSelector(state => state.AuthReducer.isAdmin);
    const isOperator = useSelector(state => state.AuthReducer.isOperator);
    const isAprover = useSelector(state => state.AuthReducer.isApprover);

    const [customPeriodo, setCustomPeriodo] = useState(() => {
        if (location.state !== null && location.state.dataRecebimento) {
            const dates = location.state.dataRecebimento;
            if (dates.length !== 2) {
                const dateFormatted = moment(dates, "DD/MM/YYYY");
                return [dateFormatted, dateFormatted];
            } else {
                const [date1, date2] = dates;
                const startDate = moment(date1, "DD/MM/YYYY");
                const endDate = moment(date2, "DD/MM/YYYY");
                return [startDate, endDate];
            }
        } else {
            return [null, null];
        }
    });

    const estabelecimentoId = useSelector(state => state.StoreReducer.estabelecimentoId);
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    useEffect(() => {
        if (location.state !== null) {
            setPeriodo("custom_periodo");
        }
    }, [location.state]);

    useEffect(() => {
        if (estabelecimentoId !== 0 && periodo !== "custom_periodo") {
            getNotasRecebidos();
        }
    }, [estabelecimentoId, arrowCounter, customPeriodo, periodo]);

    const getNotasRecebidos = () => {
        const dataRequest = {
            estabelecimento_id: estabelecimentoId,
            periodo: periodo,
        };
        if (arrowCounter !== 0) {
            dataRequest.plus_minus = arrowCounter;
        }
        if (periodo === "custom_periodo") {
            let start_date = customPeriodo[0]._d;
            let end_date = customPeriodo[1]._d;
            dataRequest.start_date = `${start_date.getDate()}/${start_date.getMonth() + 1}/${start_date.getFullYear()}`;
            dataRequest.end_date = `${end_date.getDate()}/${end_date.getMonth() + 1}/${end_date.getFullYear()}`;
        }
        api.GetNotasRecebidos(dataRequest).then(response => {
            let data = response.data;

            setFilterPlaceholders(data.filter_placeholders);
            setSpecificDate(data?.specific_date || "");
            let notasData = data.notas_data;
            if (notasData.length === 0) {
                dispatch(showSnackMessage({message: "Nenhum registro encontrado", severity: "info"}));
            }
            setNotasRecebidosData(notasData);
        }).catch(() => {
            dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
        });
    };

    const downloadNota = (id, extension) => {
        let dataRequest = {
            nota_id: id,
            extension: extension,
        };
        api.PostNotasRecebidos(dataRequest).then(response => {
            let url = response.data.url;

            window.open(url, "_blank");
        }).catch(() => {
            dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
        });
    };

    const patchInformarReceita = () => {
        let _selectedNotas = selectedNotas.map(nota => nota.split("-")[0]);
        let dataRequest = {
            notas_id: _selectedNotas,
        };
        api.PatchNotasRecebidos(dataRequest).then(() => {
            dispatch(showSnackMessage({message: "Nota(s) informada(s) à receita!", severity: "success"}));
            window.location.reload();
        }).catch(() => {
            dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
        });
    };

    const columns = [
        { field: "data_emissao_str", headerName: "Emissão", flex: 1, align: "center", headerAlign: "center" },
        { field: "nome_emitente", headerName: "Fornecedor", flex: 1, align: "center", headerAlign: "center" },
        { field: "serie_nfe", headerName: "Série - NF", flex: 1, align: "center", headerAlign: "center" },
        {
            field: "situacao",
            headerName: "Status",
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <span style={{backgroundColor: GRAY_LABEL_UX, padding: 8, borderRadius: "3px", color: temaEscuro ? LINE_TABLE : WHITE_TABLE }}>
                        {capitalizeFirstLetter(params.value)}
                    </span>
                );
            }
        },
        {
            field: "download",
            headerName: "Download",
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (
                <Grid container spacing={1} sx={{display: "flex", justifyContent: "center"}}>
                    <Grid item>
                        <GrayButton
                            title="pdf"
                            onClick={() => downloadNota(params.row.id, "pdf")}
                        />
                    </Grid>
                    <Grid item>
                        <GrayButton
                            title="xml"
                            onClick={() => downloadNota(params.row.id, "xml")}
                        />
                    </Grid>
                </Grid>
            )
        },
        {
            field: "valor_total",
            headerName: "Valor R$",
            flex: 1,
            align: "center",
            headerAlign: "center",
            valueFormatter: (params) => formatterCurrency(params, 2)
        },
        {
            field: "chave",
            headerName: "Chave",
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (
                <GrayButton
                    disabled={params.row.situacao !== "autorizada" || !(isAdmin || isOperator || isAprover)}
                    title={"Gerar Compra"}
                    isRedirect
                    path={"/gerar_compra"}
                    state={{ chave: params.row.id }}
                />
            )
        }
    ];

    const handleDateChange = (newValue) => {
        setCustomPeriodo(newValue);
    };

    return (
        <React.Fragment>
            <Grid container>
                <ReprocessarNotaModal
                    openReprocessarNota={openReprocessarNota}
                    setOpenReprocessarNota={setOpenReprocessarNota}
                    getNotasRecebidos={getNotasRecebidos}
                />
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item>
                            <FilterEXM
                                placeholders={filterPlaceholders}
                                periodo={periodo}
                                setPeriodo={setPeriodo}
                                specificDate={specificDate}
                                setSpecificDate={setSpecificDate}
                                arrowCounter={arrowCounter}
                                setArrowCounter={setArrowCounter}
                                callFunction={getNotasRecebidos}
                            />
                            {
                                periodo === "custom_periodo" && (
                                    <DateRangePicker
                                        value={customPeriodo}
                                        onChange={handleDateChange}
                                        localeText={{ start: "Início", end: "Fim" }}
                                        sx={{width: "330px", marginTop: 1}}
                                        format="DD/MM/YYYY"
                                        slotProps={{
                                            textField: {
                                                size: "small",
                                                sx: {backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX}
                                            }
                                        }}
                                    />
                                )
                            }
                        </Grid>
                        {
                            (isAdmin || isOperator || isAprover) && (
                                <Grid item>
                                    <GrayButton
                                        title="Reprocessar pela chave"
                                        onClick={() => setOpenReprocessarNota(true)}
                                    />
                                </Grid>
                            )
                        }
                        <Grid item xs={12} height={"calc(100vh - 196px - 72px - 70px)"}>
                            <DataGrid
                                checked={(isAdmin || isOperator || isAprover)}
                                row={(row) => (`${row.id}-${row.data_emissao_str}-${row.serie}-${row.status}-${row.valor}`)}
                                rows={(row) => row.id}
                                columns={columns}
                                data={notasRecebidosData}
                                disableRowSelectionOnClick
                                customRowSelectionModelChange={(item) => setSelectedNotas(item)}
                                customFooter={
                                    (isAdmin || isOperator || isAprover) && 
                                        <NotasFooter
                                            patchInformarReceita={() => patchInformarReceita()}
                                            notas={selectedNotas}
                                            title={"A informar"}
                                        />
                                }
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default NotasRecebidosTab;