import React, { useEffect } from "react";
import { DataGridPremium, useGridApiRef, useGridSelector, useGridApiContext, gridPageCountSelector, GridPagination} from "@mui/x-data-grid-premium";
import { ptBR } from "@mui/x-data-grid-premium/locales";
import {GRAY_STRONG_UX, WHITE_THEME_BLACK, BLUE_THEME, INFO_THEME, WHITE_ESCRITA_THEME, LINE_TABLE, PERFIL_THEME} from "../../shared/utils";
import { Grid } from "@mui/material";
import {useSelector} from "react-redux";
import MuiPagination from "@mui/material/Pagination";
import GrayButton from "../Buttons/GrayButton";
import OptionsEXMSelectedItems from "../Buttons/OptionsEXMSelectedItems";
import OptionsCadastrosSelectedItems from "../Buttons/OptionsCadastrosSelectedItems";


export default function DataGridWithPagination(props) {

    const { row, data, columns, pagination, isRowSelectable, checked, customRowSelectionModelChange, notifications=[], handleReadNotification, exmItems=0, isEXM=false, getExtratoMovimentacoes, deleteMovimentacoesLote, pageSizeOptions=[0], setRowsSelected, onRowClick, cadastroItems = 0, deleteCadastros } = props;

    const apiRef = useGridApiRef();
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const isAdmin = useSelector(state => state.AuthReducer.isAdmin);
    const isApprover = useSelector(state => state.AuthReducer.isApprover);
    const isOperator = useSelector(state => state.AuthReducer.isOperator);

    function Pagination({ page, onPageChange, className }) {
        const apiRef = useGridApiContext();
        const pageCount = useGridSelector(apiRef, gridPageCountSelector);
    
        return (
            <MuiPagination
                color="primary"
                className={className}
                count={pageCount}
                page={page + 1}
                onChange={(event, newPage) => {
                    onPageChange(event, newPage - 1);
                }}
            />
        );
    }
    
    function CustomPagination(props) {

        return (
            <React.Fragment>
                <GridPagination ActionsComponent={Pagination} {...props} sx={{display: "flex", alignItems: "center", justifyContent: "center", height: "60px", width: "100%"}} />
                {
                    notifications.length > 0 && (
                        <Grid container sx={{width: "400px", display: "flex", justifyContent: "end", paddingRight: 1.2}}>
                            <Grid item>
                                <GrayButton
                                    title={"Marcar como lida"}
                                    onClick={() => handleReadNotification()}
                                />
                            </Grid>
                        </Grid>
                    )
                }
                {
                    (exmItems.length > 0 && (isAdmin || isOperator || isApprover)) && (
                        <Grid container sx={{width: "400px", display: "flex", justifyContent: "end", paddingRight: 1.2}}>
                            <Grid item>
                                <OptionsEXMSelectedItems exmItems={exmItems} data={data} getExtratoMovimentacoes={getExtratoMovimentacoes} deleteMovimentacoesLote={deleteMovimentacoesLote} />
                            </Grid>
                        </Grid>
                    )
                }
                {
                    (cadastroItems.length > 0 && (isAdmin || isOperator || isApprover)) && (
                        <Grid container sx={{width: "400px", display: "flex", justifyContent: "end", paddingRight: 1.2}}>
                            <Grid item>
                                <OptionsCadastrosSelectedItems cadastroItems={cadastroItems} deleteCadastros={deleteCadastros} />
                            </Grid>
                        </Grid>
                    )
                }
            </React.Fragment>
        );
    }

    useEffect(() => {
        if (exmItems) {
            const selectedRows = exmItems.map(item => apiRef.current.getRow(item));
            setRowsSelected(prevRows => {
                // Array com as linhas já selecionadas
                const newRows = [...prevRows];
                // Adiciona novas linhas selecionadas
                selectedRows.forEach(row => {
                    if (!newRows.some(existingRow => existingRow.id === row.id)) {
                        newRows.push(row);
                    }
                });
                // Remove as linhas desmarcadas
                return newRows.filter(existingRow => selectedRows.some(row => row.id === existingRow.id));
            });
        }
    }, [exmItems]);
    

    return (
        <DataGridPremium
            sx={{
                "& .MuiDataGrid-cell.MuiDataGrid-cell--editing" : {
                    backgroundColor: "transparent"
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                    fontSize: "16px",
                    fontWeight: "500",
                    color: temaEscuro ? LINE_TABLE : GRAY_STRONG_UX
                },
                "& ::-webkit-scrollbar-thumb": {
                    backgroundColor: temaEscuro ? PERFIL_THEME : WHITE_ESCRITA_THEME
                },
                "& .MuiDataGrid-row--editing .MuiDataGrid-cell": {
                    backgroundColor: "transparent"
                },
                fontSize: "14px", fontWeight: 400, borderRadius: 2, borderColor: temaEscuro && INFO_THEME
            }}
            style={{"--DataGrid-containerBackground": temaEscuro && BLUE_THEME, "--DataGrid-pinnedBackground": temaEscuro && BLUE_THEME, backgroundColor: temaEscuro && BLUE_THEME, color: temaEscuro ? WHITE_THEME_BLACK : GRAY_STRONG_UX}}
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            checkboxSelection={checked}
            onRowSelectionModelChange={customRowSelectionModelChange}
            apiRef={apiRef}
            rows={data}
            getRowId={row}
            columns={columns}
            onCellClick={(params) => {                
                if ((params.colDef.field != "acoes" && params.colDef.field != "__check__") && onRowClick) {
                    onRowClick(params);
                }
            }}
            pagination={pagination}
            isRowSelectable={isRowSelectable}
            disableRowSelectionOnClick={isEXM}
            pageSizeOptions={pageSizeOptions}
            slots={{
                pagination: CustomPagination
            }}
        />
    );
}