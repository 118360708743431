import React from "react";
import { useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Button from "@mui/material/Button";


const CertificadoAlert = ({contasSemCertificado}) => {
    const nav = useNavigate();

    return (
        <Alert
            severity="info"
            sx={{m: "1rem 0"}}
            action={
                <Button sx={{marginTop: ".6rem"}} color="inherit" onClick={() => nav("/upload_certificado")}>
                    Vincular
                </Button>
            }
        >
            <AlertTitle>Conta(s): {contasSemCertificado.map(conta => conta.apelido).join(",")} sem certificado vinculado</AlertTitle>
            Existem contas sem certificados vinculados para o(s) banco(s): {contasSemCertificado.map(conta => conta.banco).join(",")}.
        </Alert>
    );
};

export default CertificadoAlert;