import React, { useState, useEffect } from "react";
import Skeleton from "@mui/material/Skeleton";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import api from "../../axios";
import { paperStyle, BLUE_THEME, PAPER_PADDING_THEME, BORDER_BLACK_THEME_CARD, LINE_TABLE, BORDER_PAPER } from "../../shared/utils";
import Header from "../../components/Titles/Header";
import {useDispatch, useSelector} from "react-redux";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { changeOpenComponent } from "../../actions/StoreActions";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import RowHistoric from "../../components/Table/RowHistoric";
import FilterSingleOption from "../../components/Filter/FilterSingleOption";


const ChangeLogs = () => {
    const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
    const [usersOptions, setUsersOptions] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [changeLogs, setChangeLogs] = useState([]);

	const isOpenHistoric = useSelector(state => state.StoreReducer.openComponent);
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
	const isAdmin = useSelector(state => state.AuthReducer.isAdmin);

    useEffect(() => {
        getLogs();
        getUsers();
    }, []);

    useEffect(() => {
        getLogs();
    }, [selectedUser]);

    const getLogs = () => {
        setLoading(true);
        if (selectedUser) {
            let dataRequest = {
                user_id: selectedUser.value
            };
            api.GetLogs(dataRequest).then((response) => {
                setChangeLogs(response.data);
                setLoading(false);
            }).catch(() => {
                setLoading(false);
            });
        } else {
            api.GetLogs().then((response) => {
                setChangeLogs(response.data);
                setLoading(false);
            }).catch(() => {
                setLoading(false);
            });
        }
    };

    const getUsers = () => {
        setLoading(true);
        api.GetAllUsers().then((response) => {
            setUsersOptions(response.data);
            setLoading(false);
        }).catch(() => {
            setLoading(false);
        });
    };

    const handleAbrirTodos = () => {
		dispatch(changeOpenComponent(!isOpenHistoric));
	};

    return (
        <div className="main">
            <Header title="Histórico de Modificações"/>
            <Paper
                sx={{
                    ...paperStyle,
                    backgroundColor: temaEscuro ? PAPER_PADDING_THEME : LINE_TABLE,
                    backgroundImage: temaEscuro && "none", 
                    border: temaEscuro ? `1px solid ${BORDER_BLACK_THEME_CARD}` : `1px solid ${BORDER_PAPER}`
                }}
                data-testid="perfil-screen"
            >
                {
                    loading ? (
                        [...Array(15)].map((_, index) => (
                            <Skeleton key={index} height={50} />
                        ))
                    ) : (
                        <Grid container>
                            {
                                isAdmin && (
                                    <Grid item xs={12}>
                                        <FilterSingleOption
                                            filterOptions={usersOptions}
                                            selectedOption={selectedUser}
                                            setSelectedOption={setSelectedUser}
                                            placeholder="Usuário"
                                            width="375px"
                                            onChange={getLogs}
                                        />
                                    </Grid>
                                )
                            }
                            <Grid item xs={12} sx={{marginTop: 2}}>
                                <TableContainer>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{fontWeight: "bold", backgroundColor: temaEscuro && BLUE_THEME}} width="90%" align="left">
                                                    Data
                                                </TableCell>
                                                <TableCell style={{fontWeight: "bold", backgroundColor: temaEscuro && BLUE_THEME}} width="5%" align="right">
                                                    Hora
                                                </TableCell>

                                                <TableCell
                                                    data-testid="open-rows"
                                                    align="right"
                                                    style={{cursor: "pointer", backgroundColor: temaEscuro && BLUE_THEME}}
                                                    width="5%"
                                                    onClick={() => handleAbrirTodos()}
                                                >
                                                    {isOpenHistoric ? <KeyboardDoubleArrowUpIcon/> : <KeyboardDoubleArrowDownIcon/>}
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {changeLogs.map((log, index) => (
                                                <RowHistoric hasMonthYear={true} data={log.data} hora={log.hora} values={log.values} key={`${log.data}-${log.hora}-${index}`}/>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    )
                }
            </Paper>
        </div>
    );
};

export default ChangeLogs;