export const CHANGE_STORE = "CHANGE_STORE";
export const CHANGE_ESTABELECIMENTO = "CHANGE_ESTABELECIMENTO";
export const CHANGE_TOKEN = "CHANGE_TOKEN";

export const CHANGE_DEVICE_TOKEN = "CHANGE_DEVICE_TOKEN";
export const CHANGE_EMAIL = "CHANGE_EMAIL";
export const CHANGE_NAME = "CHANGE_NAME";
export const CHANGE_CODE = "CHANGE_CODE";
export const CHANGE_RESETAR_SENHA = "CHANGE_RESETAR_SENHA";
export const CHANGE_IS_ADMIN = "CHANGE_IS_ADMIN";
export const CHANGE_OPEN_COMPONENT = "CHANGE_OPEN_COMPONENT";
export const CHANGE_OPEN_ACCORDION_SELECTED = "CHANGE_OPEN_ACCORDION_SELECTED";
export const CHANGE_SCROLL_POSITION_DATA_GRID = "CHANGE_SCROLL_POSITION_DATA_GRID";
export const SHOW_SNACK_MESSAGE = "SHOW_SNACK_MESSAGE";
export const CLOSE_SNACK_MESSAGE = "CLOSE_SNACK_MESSAGE";
export const CHANGE_TEMA = "CHANGE_TEMA";
export const CHANGE_IMAGE_PERFIL = "CHANGE_IMAGE_PERFIL";
export const CHANGE_MODIFIED = "CHANGE_MODIFIED";
export const CHANGE_LOJAS = "CHANGE_LOJAS";
export const CHANGE_IS_COMMON_USER = "CHANGE_IS_COMMON_USER";
export const CHANGE_IS_OPERATOR = "CHANGE_IS_OPERATOR";
export const CHANGE_IS_APPROVER =  "CHANGE_IS_APPROVER";
export const CHANGE_IS_DESKTOP = "CHANGE_IS_DESKTOP";
export const CHANGE_NOTIFICATIONS = "CHANGE_NOTIFICATIONS";

export const CHANGE_SEGMENTOS = "CHANGE_SEGMENTOS";

export const CHANGE_ESTABELECIMENTOS = "CHANGE_ESTABELECIMENTOS";
export const CHANGE_STORE_NAME = "CHANGE_STORE_NAME";