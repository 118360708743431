import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import api from "../../axios";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Header from "../../components/Titles/Header";
import { BLUE_THEME, formatterCurrency, GRAY_PDF, GREEN_PDF, GREEN_TEXT_PDF, LINE_TABLE, PAPER_PADDING_THEME, paperStyle, YELLOW_PDF, YELLOW_TEXT_PDF } from "../../shared/utils";
import ModalTitle from "../../components/Titles/ModalTitle";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";

const DetalheNota = () => {
    const location = useLocation();

    const [fornecedor, setFornecedor] = useState("");
    const [codigoReferencia, setCodigoReferencia] = useState("");
    const [categoriaDespesa, setCategoriaDespesa] = useState("");
    const [dataCompra, setDataCompra] = useState("");
    const [produtoServicos, setProdutoServicos] = useState([]);
    const [parcelas, setParcelas] = useState([]);
    const [totalProdutos, setTotalProdutos] = useState(0);
    const [totalLiquidoProdutos, setTotalLiquidoProdutos] = useState(0);
    const [totalBrutoProdutos, setTotalBrutoProdutos] = useState(0);
    const [totalDescontoProdutos, setTotalDescontoProdutos] = useState(0);
    const [totalFreteProdutos, setTotalFreteProdutos] = useState(0);
    const [totalBrutoPago, setTotalBrutoPago] = useState(0);
    const [totalLiquidoPago, setTotalLiquidoPago] = useState(0);
    const [totalDescontoPago, setTotalDescontoPago] = useState(0);
    const [totalLiquidoEmAberto, setTotalLiquidoEmAberto] = useState(0);
    const [totalBrutoEmAberto, setTotalBrutoEmAberto] = useState(0);
    const [totalDescontoEmAberto, setTotalDescontoEmAberto] = useState(0);
    const [totalLiquidoEmAtraso, setTotalLiquidoEmAtraso] = useState(0);
    const [totalBrutoEmAtraso, setTotalBrutoEmAtraso] = useState(0);
    const [totalDescontoEmAtraso, setTotalDescontoEmAtraso] = useState(0);
    const [totalLiquidoAPagar, setTotalLiquidoAPagar] = useState(0);
    const [totalBrutoAPagar, setTotalBrutoAPagar] = useState(0);
    const [totalDescontoAPagar, setTotalDescontoAPagar] = useState(0);
    const [observacoesComplementares, setObservacoesComplementares] = useState("");

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const estabelecimentoId = useSelector(state => state.StoreReducer.estabelecimentoId);

    useEffect(() => {
        getNotaDetails(location.state);
    }, []);

    const getNotaDetails = (movimentacaoUUID) => {
        let dataRequest = {
            mv_uuid: movimentacaoUUID,
            estabelecimento_id: estabelecimentoId
        };

        api.GetNotaDetails(dataRequest).then((response) => {
            let dataResponse = response.data;
            setFornecedor(dataResponse.informacoes_compra.fornecedor);
            setCodigoReferencia(dataResponse.informacoes_compra.codigo_referencia);
            setCategoriaDespesa(dataResponse.informacoes_compra.categoria);
            setDataCompra(dataResponse.informacoes_compra.data_compra);
            setProdutoServicos(dataResponse.informacoes_compra.all_produto_servico);
            setParcelas(dataResponse.condicao_pagamento.parcelas);
            setTotalProdutos(dataResponse.informacoes_compra.produto_servico_summary.total_produtos);
            setTotalLiquidoProdutos(dataResponse.informacoes_compra.produto_servico_summary.total_liquido);
            setTotalBrutoProdutos(dataResponse.informacoes_compra.produto_servico_summary.total_bruto);
            setTotalDescontoProdutos(dataResponse.informacoes_compra.produto_servico_summary.total_desconto);
            setTotalFreteProdutos(dataResponse.informacoes_compra.produto_servico_summary.total_frete);
            setTotalBrutoPago(dataResponse.condicao_pagamento.pago.total_bruto);
            setTotalLiquidoPago(dataResponse.condicao_pagamento.pago.total_liquido);
            setTotalDescontoPago(dataResponse.condicao_pagamento.pago.total_desconto);
            setTotalLiquidoEmAberto(dataResponse.condicao_pagamento.em_aberto.total_liquido);
            setTotalBrutoEmAberto(dataResponse.condicao_pagamento.em_aberto.total_bruto);
            setTotalDescontoEmAberto(dataResponse.condicao_pagamento.em_aberto.total_desconto);
            setTotalLiquidoEmAtraso(dataResponse.condicao_pagamento.em_aberto.em_atraso_summary.total_liquido);
            setTotalBrutoEmAtraso(dataResponse.condicao_pagamento.em_aberto.em_atraso_summary.total_bruto);
            setTotalDescontoEmAtraso(dataResponse.condicao_pagamento.em_aberto.em_atraso_summary.total_desconto);
            setTotalLiquidoAPagar(dataResponse.condicao_pagamento.em_aberto.a_pagar_summary.total_liquido);
            setTotalBrutoAPagar(dataResponse.condicao_pagamento.em_aberto.a_pagar_summary.total_bruto);
            setTotalDescontoAPagar(dataResponse.condicao_pagamento.em_aberto.a_pagar_summary.total_desconto);
            setObservacoesComplementares(dataResponse.observacoes_complementares);
        }).catch(() => {

        });
    };

    const checkSituacao = (situacao) => {
        if (situacao === "Pago") {
            return (
                <Box sx={{color: temaEscuro ? GREEN_PDF : GREEN_TEXT_PDF, backgroundColor: temaEscuro ? GREEN_TEXT_PDF : GREEN_PDF, textAlign: "center", padding: ".2rem 1rem", fontWeight: "bold"}}>
                    {situacao}
                </Box>
            );
        }
        else if (situacao == "Em Aberto") {
            return (
                <Box sx={{color: temaEscuro ? YELLOW_PDF : YELLOW_TEXT_PDF, backgroundColor: temaEscuro ? "#c0a944" : YELLOW_PDF, textAlign: "center", padding: ".2rem 1rem", fontWeight: "bold"}}>
                    {situacao}
                </Box>
            );
        }
    };

    return (
        <div className="main">
            <Header
                title="Detalhes da Compra"
                subTitle="Notas de Compras"
                previousPage="/notas_compras"
            />
            <Paper sx={{...paperStyle, backgroundColor: temaEscuro ? PAPER_PADDING_THEME : LINE_TABLE, backgroundImage: temaEscuro && "none"}}>
                <Grid container sx={{display: "flex", flexDirection: "column"}}>
                    <ModalTitle
                        title="Informações da compra"
                    />
                    <Grid item sx={{display: "flex", gap: 1, mt: 3}}>
                        <Grid container sx={{display: "flex", flexDirection: "column", gap: 1}}>
                            <span style={{fontSize: "13px", fontWeight: "bold"}}>Fornecedor</span>
                            <span style={{fontSize: "14px"}}>{fornecedor}</span>
                        </Grid>
                        <Grid container sx={{display: "flex", flexDirection: "column", gap: 1}}>
                            <span style={{fontSize: "13px", fontWeight: "bold"}}>Código de referência</span>
                            <span style={{fontSize: "14px"}}>{codigoReferencia}</span>
                        </Grid>
                        <Grid container sx={{display: "flex", flexDirection: "column", gap: 1}}>
                            <span style={{fontSize: "13px", fontWeight: "bold"}}>Categoria de despesa</span>
                            <span style={{fontSize: "14px"}}>{categoriaDespesa}</span>
                        </Grid>
                        <Grid container sx={{display: "flex", flexDirection: "column", gap: 1}}>
                            <span style={{fontSize: "13px", fontWeight: "bold"}}>Data da compra</span>
                            <span style={{fontSize: "14px"}}>{dataCompra}</span>
                        </Grid>
                    </Grid>
                    <Grid item sx={{maxHeight: "30vh", mt: 2}}>
                        <TableContainer>
                            <Table sx={{".MuiTableCell-root": {
                                            padding: ".8rem 1rem",
                                            fontWeight: "400"
                                        }}}>
                                <TableHead sx={{backgroundColor: temaEscuro ? BLUE_THEME : GRAY_PDF}}>
                                    <TableRow>
                                        <TableCell>Produto/Serviço</TableCell>
                                        <TableCell>Tipo</TableCell>
                                        <TableCell>Detalhes</TableCell>
                                        <TableCell align="center">Quantidade</TableCell>
                                        <TableCell align="right">Valor Unitário</TableCell>
                                        <TableCell align="right">Subtotal</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        produtoServicos.map((produtoServico) => {
                                            return (
                                                <TableRow key={produtoServico.id}>
                                                    <TableCell>{produtoServico.nome}</TableCell>
                                                    <TableCell>{produtoServico.tipo}</TableCell>
                                                    <TableCell>{produtoServico.detalhes}</TableCell>
                                                    <TableCell align="center">{produtoServico.quantidade}</TableCell>
                                                    <TableCell align="right">{formatterCurrency(produtoServico.valor_unitario, 2)}</TableCell>
                                                    <TableCell align="right">{formatterCurrency(produtoServico.valor_total, 2)}</TableCell>
                                                </TableRow>
                                            );
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item sx={{mt: 2}}>
                        <Accordion sx={{backgroundColor: temaEscuro && BLUE_THEME, backgroundImage: temaEscuro && "none"}}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                <Grid container sx={{display: "flex", justifyContent: "space-between"}}>
                                    <Grid item sx={{display: "flex", flexDirection: "column", gap: .5}}>
                                        <span style={{fontSize: "18px", fontWeight: "bold"}}>Total da compra</span>
                                        <span style={{fontSize: "12px", fontWeight: "300"}}>Total de produtos: {totalProdutos}</span>
                                    </Grid>
                                    <Grid item sx={{display: "flex", flexDirection: "column", gap: .5, mr: "1rem"}}>
                                        <span style={{fontSize: "12px", fontWeight: "400", textAlign: "left"}}>Total líquido</span>
                                        <span style={{fontSize: "14px", fontWeight: "bold", textAlign: "right", color: totalLiquidoProdutos >= 0 ? "#26B547" : "#FF3D32"}}>{formatterCurrency(totalLiquidoProdutos, 2)}</span>
                                    </Grid>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails sx={{display: "flex"}}>
                                <Table sx={{display: "flex", justifyContent: "flex-end"}}>
                                    <TableBody sx={{
                                        ".MuiTableCell-root": {
                                            borderBottom: "none",
                                            padding: "0 0 .5rem 5rem"
                                        }}}
                                    >
                                        <TableRow>
                                            <TableCell align="right" sx={{color: "#9B9B9B"}}>Total bruto:</TableCell>
                                            <TableCell align="right" sx={{fontWeight: "bold"}}>{formatterCurrency(totalBrutoProdutos, 2)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right" sx={{color: "#9B9B9B"}}>Frete:</TableCell>
                                            <TableCell align="right" sx={{fontWeight: "bold"}}>{formatterCurrency(totalFreteProdutos, 2)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right" sx={{color: "#9B9B9B"}}>Desconto:</TableCell>
                                            <TableCell align="right" sx={{fontWeight: "bold"}}>{formatterCurrency(totalDescontoProdutos, 2)}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                                <div style={{marginLeft: "40px"}}></div>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
            </Paper>
            <Paper sx={{...paperStyle, backgroundColor: temaEscuro ? PAPER_PADDING_THEME : LINE_TABLE, backgroundImage: temaEscuro && "none", mt: 2}}>
                <Grid container sx={{display: "flex", flexDirection: "column"}}>
                    <ModalTitle
                        title="Condição de pagamento"
                    />
                    <Grid item sx={{maxHeight: "30vh", mt: 3}}>
                        <TableContainer>
                            <Table sx={{".MuiTableCell-root": {
                                            padding: ".8rem 1rem",
                                            fontWeight: "400",
                                            textAlign: "center"
                                        }}}>
                                <TableHead sx={{backgroundColor: temaEscuro ? BLUE_THEME : GRAY_PDF}}>
                                    <TableRow>
                                        <TableCell>Data</TableCell>
                                        <TableCell>Parcela</TableCell>
                                        <TableCell>Forma de Pagamento</TableCell>
                                        <TableCell>Conta</TableCell>
                                        <TableCell>Valor</TableCell>
                                        <TableCell>Pago</TableCell>
                                        <TableCell>Em aberto</TableCell>
                                        <TableCell>Situação</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        parcelas.map((parcela, index) => {
                                            return (
                                                <TableRow key={parcela.id}>
                                                    <TableCell>{parcela.data}</TableCell>
                                                    <TableCell>{index+1}/{parcelas.length}</TableCell>
                                                    <TableCell>{parcela.forma_pagamento}</TableCell>
                                                    <TableCell>{parcela.conta}</TableCell>
                                                    <TableCell>{formatterCurrency(parcela.valor, 2)}</TableCell>
                                                    <TableCell>{formatterCurrency(parcela.pago, 2)}</TableCell>
                                                    <TableCell>{formatterCurrency(parcela.em_aberto, 2)}</TableCell>
                                                    <TableCell>{checkSituacao(parcela.situacao)}</TableCell>
                                                </TableRow>
                                            );
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item sx={{mt: 2}}>
                        <Accordion sx={{backgroundColor: temaEscuro && BLUE_THEME, backgroundImage: temaEscuro && "none"}}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                <Grid container sx={{display: "flex", justifyContent: "space-between"}}>
                                    <span style={{fontSize: "18px", fontWeight: "bold"}}>Pago</span>
                                    <Grid item sx={{display: "flex", flexDirection: "column", gap: .5, mr: "1rem"}}>
                                        <span style={{fontSize: "12px", fontWeight: "400", textAlign: "left"}}>Total pago</span>
                                        <span style={{fontSize: "14px", fontWeight: "bold", textAlign: "right", color: totalLiquidoProdutos >= 0 ? "#26B547" : "#FF3D32"}}>{formatterCurrency(totalLiquidoPago, 2)}</span>
                                    </Grid>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails sx={{display: "flex"}}>
                                <Table sx={{display: "flex", justifyContent: "flex-end"}}>
                                    <TableBody sx={{
                                        ".MuiTableCell-root": {
                                            borderBottom: "none",
                                            padding: "0 0 .5rem 5rem"
                                        }}}
                                    >
                                        <TableRow>
                                            <TableCell align="right" sx={{color: "#9B9B9B"}}>Parcelas:</TableCell>
                                            <TableCell align="right" sx={{fontWeight: "bold"}}>{formatterCurrency(totalBrutoPago, 2)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right" sx={{color: "#9B9B9B"}}>Descontos:</TableCell>
                                            <TableCell align="right" sx={{fontWeight: "bold"}}>{formatterCurrency(totalDescontoPago, 2)}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                                <div style={{marginLeft: "40px"}}></div>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <Grid item sx={{mt: 2}}>
                        <Accordion sx={{backgroundColor: temaEscuro && BLUE_THEME, backgroundImage: temaEscuro && "none"}}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                <Grid container sx={{display: "flex", justifyContent: "space-between"}}>
                                    <span style={{fontSize: "18px", fontWeight: "bold"}}>Em aberto</span>
                                    <Grid item sx={{display: "flex", gap: 1.5, mr: "1rem"}}>
                                        <div style={{display: "flex", flexDirection: "column", gap: 1}}>
                                            <span style={{fontSize: "12px", fontWeight: "400", textAlign: "right"}}>Total a pagar</span>
                                            <span style={{fontWeight: "bold", textAlign: "right", color: "#26B547"}}>{formatterCurrency(totalLiquidoAPagar, 2)}</span>
                                        </div>
                                        <AddIcon />
                                        <div style={{display: "flex", flexDirection: "column", gap: 1}}>
                                            <span style={{fontSize: "12px", fontWeight: "400", textAlign: "right"}}>Total em atraso</span>
                                            <span style={{fontWeight: "bold", textAlign: "right", color: "#FF3D32"}}>{formatterCurrency(totalLiquidoEmAtraso, 2)}</span>
                                        </div>
                                        <DragHandleIcon />
                                        <div style={{display: "flex", flexDirection: "column", gap: 1}}>
                                            <span style={{fontSize: "12px", fontWeight: "400", textAlign: "right"}}>Total em aberto</span>
                                            <span style={{fontWeight: "bold", textAlign: "right", color: "#2687E9"}}>{formatterCurrency(totalLiquidoEmAberto, 2)}</span>
                                        </div>
                                    </Grid>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails sx={{display: "flex"}}>
                                <Table sx={{display: "flex", justifyContent: "flex-end"}}>
                                    <TableBody sx={{
                                        ".MuiTableCell-root": {
                                            borderBottom: "none",
                                            padding: "0 0 .5rem 5rem"
                                        }}}
                                    >
                                        <TableRow>
                                            <TableCell align="right" sx={{color: "#9B9B9B"}}>Parcelas:</TableCell>
                                            <TableCell align="right" sx={{fontWeight: "bold"}}>{formatterCurrency(totalBrutoAPagar, 2)}</TableCell>
                                            <TableCell align="right" sx={{fontWeight: "bold"}}>{formatterCurrency(totalBrutoEmAtraso, 2)}</TableCell>
                                            <TableCell align="right" sx={{fontWeight: "bold"}}>{formatterCurrency(totalBrutoEmAberto, 2)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right" sx={{color: "#9B9B9B"}}>Descontos:</TableCell>
                                            <TableCell align="right" sx={{fontWeight: "bold"}}>{formatterCurrency(totalDescontoAPagar, 2)}</TableCell>
                                            <TableCell align="right" sx={{fontWeight: "bold"}}>{formatterCurrency(totalDescontoEmAtraso, 2)}</TableCell>
                                            <TableCell align="right" sx={{fontWeight: "bold"}}>{formatterCurrency(totalDescontoEmAberto, 2)}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                                <div style={{marginLeft: "46px"}}></div>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
            </Paper>

            <Paper sx={{...paperStyle, backgroundColor: temaEscuro ? PAPER_PADDING_THEME : LINE_TABLE, backgroundImage: temaEscuro && "none", mt: 2}}>
            <Grid container sx={{display: "flex", flexDirection: "column"}}>
                <ModalTitle
                    title="Observações complementares"
                />
                <Grid item sx={{mt: 2}}>
                    <span>{observacoesComplementares}</span>
                </Grid>
            </Grid>
            </Paper>
        </div>
    );
};

export default DetalheNota;