export const steps = [
    {
        selector: ".buttonTabs",
        content: "Status dos boletos desta loja."
    },
    {
        selector: ".buttonCopyLink",
        content: "Copia o link dos boletos desta loja."
    },
    {
        selector: ".buttonArquivo",
        content: "Adiciona novos boletos aos boletos."
    }
];