import React, { useState, useEffect } from "react";
import Header from "../../components/Titles/Header";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
    BLUE_THEME,
    BORDER_BLACK_THEME_CARD,
    BORDER_PAPER, GRAY_BG_UX,
    LINE_TABLE,
    PAPER_PADDING_THEME,
    paperStyle,
    YELLOW_BG_UX,
    BLACK_TABLE,
    GRAY_LABEL_UX_THEME, BORDER_TABLE, WHITE_ESCRITA_THEME, BLACK_LABEL_UX
} from "../../shared/utils";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import api from "../../axios";
import FilterSingleOption from "../../components/Filter/FilterSingleOption";
import FilterEXM from "../../components/Filter/FilterEXM";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import MovimentacoesConta from "../../components/Tabs/MovimentacoesConta";
import ConciliacoesConta from "../../components/Tabs/ConciliacoesConta";
import BB from "./LogoBancos/BB.png";
import VXMF from "./LogoBancos/VXMF.png";
import {showSnackMessage} from "../../actions/SnackActions";
import Skeleton from "@mui/material/Skeleton";
import GrayButton from "../../components/Buttons/GrayButton";


const DetalhaConta = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [filterPlaceholders, setFilterPlaceholders] = useState({});
    const [periodo, setPeriodo] = useState("este_mes");
    const [specificDate, setSpecificDate] = useState("");
    const [arrowCounter, setArrowCounter] = useState(0);
    const [customPeriodo, setCustomPeriodo] = useState([null, null]);

    const [selectedContaId, setSelectedContaId] = useState(location?.state.selectedConta || null);
    const [selectedConta, setSelectedConta] = useState(null);
    const [contasOptions, setContasOptions] = useState([]);
    const [detalhesData, setDetalhesData] = useState([]);

    const [selectedTab, setSelectedTab] = useState("conciliacoes");
    const [bancoLogo, setBancoLogo] = useState(null);
    const [loading, setLoading] = useState(false);

    const estabelecimentoId = useSelector(state => state.StoreReducer.estabelecimentoId);
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    useEffect(() => {
        if (!location.state || !estabelecimentoId) {
            navigate("/contas");
        }
    }, []);

    useEffect(() => {
        if (estabelecimentoId !== 0 && periodo !== "custom_periodo") {
            getContas();
            getDetalhesConta();
        }
    }, [estabelecimentoId, arrowCounter, customPeriodo, periodo, selectedContaId]);

    useEffect(() => {
        if (!selectedContaId === null) {
            for (let conta of location.state.contas) {
                if (conta.id === selectedContaId.value) {
                    setSelectedConta(conta);
                }
            }
        }
    }, [selectedContaId]);

    useEffect(() => {
        if (selectedConta) {
            if (selectedConta.banco_formatted === "BB") {
                setBancoLogo(BB);
            }
        }
    }, [selectedConta]);

    const getContas = () => {
        let dataRequest = {
            estabelecimento_id: estabelecimentoId,
        };
        api.RetrieveContas(dataRequest).then(response => {
            setContasOptions(response.data);
        });
    };

    const getDetalhesConta = () => {
        setLoading(true);
        let dataRequest = {
            estabelecimento_id: estabelecimentoId,
            conta_id: selectedContaId.value,
            periodo: periodo,
            plus_minus: arrowCounter,
        };
        if (arrowCounter !== 0) {
            dataRequest.plus_minus = arrowCounter;
        }
        if (periodo === "custom_periodo") {
            let start_date = customPeriodo[0]._d;
            let end_date = customPeriodo[1]._d;
            dataRequest.start_date = `${start_date.getDate()}/${start_date.getMonth() + 1}/${start_date.getFullYear()}`;
            dataRequest.end_date = `${end_date.getDate()}/${end_date.getMonth() + 1}/${end_date.getFullYear()}`;
        }
        api.GetDetalharConta(dataRequest).then(response => {
            let data = response.data;

            setFilterPlaceholders(data.filter_placeholders);
            setSpecificDate(data?.specific_date || "");
            setDetalhesData(data.detalhes_data);
            setLoading(false);
        });
    };

    const handleContaPadrao = () => {
        const dataRequest = {
            conta_id: selectedContaId.value,
            estabelecimento_id: estabelecimentoId,
        };
        api.PatchContaPadrao(dataRequest).then(() => {
            dispatch(showSnackMessage({ message: "Esta agora é a sua conta padrão!", severity: "success" }));
        }).catch((error) => {
            dispatch(showSnackMessage({ message: error ? error.response.data : "Algo deu errado! Tente novamente mais tarde", severity: error ? "info" : "error" }));
        });
    };

    const handleDateChange = (newValue) => {
        setCustomPeriodo(newValue);
    };

    const handleChange = (event, value) => {
        setSelectedTab(value);
    };

    return (
        <React.Fragment>
            <div className="main" data-testid="despesas">
                <Header title="Detalhamento" subTitle="Contas" previousPage="/contas" />
                <Paper sx={{
                    ...paperStyle,
                    backgroundColor: temaEscuro ? PAPER_PADDING_THEME : LINE_TABLE,
                    backgroundImage: temaEscuro && "none",
                    border: temaEscuro ? `1px solid ${BORDER_BLACK_THEME_CARD}` : `1px solid ${BORDER_PAPER}`
                }}>
                    {
                        loading ? (
                            [...Array(15).keys()].map((i,d) =>{
                                return(
                                    <Skeleton key={d} height={50}/>
                                );
                            })
                        ) : (
                            <React.Fragment>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={6}>
                                                <FilterSingleOption
                                                    placeholder={"Conta"}
                                                    filterOptions={contasOptions}
                                                    selectedOption={selectedContaId}
                                                    setSelectedOption={setSelectedContaId}
                                                />
                                            </Grid>
                                            <Grid item xs={6} sx={{display: "flex", justifyContent: "end"}}>
                                                <Grid container spacing={2} sx={{display: "flex", justifyContent: "end"}}>
                                                    <Grid item>
                                                        <FilterEXM
                                                            placeholders={filterPlaceholders}
                                                            periodo={periodo}
                                                            setPeriodo={setPeriodo}
                                                            specificDate={specificDate}
                                                            setSpecificDate={setSpecificDate}
                                                            arrowCounter={arrowCounter}
                                                            setArrowCounter={setArrowCounter}
                                                            callFunction={getDetalhesConta}
                                                        />
                                                        {
                                                            periodo === "custom_periodo" && (
                                                                <DateRangePicker
                                                                    value={customPeriodo}
                                                                    onChange={handleDateChange}
                                                                    localeText={{ start: "Início", end: "Fim" }}
                                                                    sx={{width: "330px", marginTop: 1}}
                                                                    format="DD/MM/YYYY"
                                                                    slotProps={{
                                                                        textField: {
                                                                            size: "small",
                                                                            sx: {backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX}
                                                                        }
                                                                    }}
                                                                />
                                                            )
                                                        }
                                                    </Grid>
                                                    <Grid item>
                                                        <GrayButton
                                                            title="Tornar conta padrão"
                                                            onClick={handleContaPadrao}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Tabs
                                            value={selectedTab}
                                            onChange={handleChange}
                                            TabIndicatorProps={{style: {backgroundColor: "transparent"}}}
                                            className="buttonTabs"
                                        >
                                            <Tab
                                                value={"conciliacoes"}
                                                label="Conciliações pendentes"
                                                sx={{
                                                    borderRadius: "20px",
                                                    color: temaEscuro ? GRAY_LABEL_UX_THEME : BLACK_TABLE,
                                                    backgroundColor: selectedTab === "conciliacoes" ? YELLOW_BG_UX : "transparent",
                                                    "&.Mui-selected": { // Use "&.Mui-selected" para selecionar o componente quando estiver selecionado
                                                        color: BLACK_LABEL_UX,
                                                    },
                                                }}
                                            />
                                            <Tab
                                                value={"movimentacoes"}
                                                label="Movimentações"
                                                sx={{
                                                    borderRadius: "20px",
                                                    color: temaEscuro ? GRAY_LABEL_UX_THEME : BLACK_TABLE,
                                                    backgroundColor: selectedTab === "movimentacoes" ? YELLOW_BG_UX : "transparent",
                                                    "&.Mui-selected": { // Use "&.Mui-selected" para selecionar o componente quando estiver selecionado
                                                        color: BLACK_LABEL_UX,
                                                    },
                                                }}
                                            />
                                        </Tabs>
                                    </Grid>
                                    <Grid item xs={12} sx={{
                                        border: 0,
                                        borderBottom: temaEscuro ? `1px solid ${BORDER_TABLE}` : `1px solid ${WHITE_ESCRITA_THEME}`,
                                        mb: 2.5,
                                        ml: 2
                                    }}/>
                                </Grid>
                                {selectedTab === "movimentacoes" && (
                                    <MovimentacoesConta
                                        bancoLogo={bancoLogo}
                                        apelidoConta={selectedConta?.apelido}
                                        saldoConta={selectedConta?.saldo_final}
                                        detalhesData={detalhesData}
                                    />
                                )}
                                {selectedTab === "conciliacoes" && (
                                    <ConciliacoesConta
                                        bancoLogo={bancoLogo}
                                        apelidoConta={selectedConta?.apelido}
                                        vxmfLogo={VXMF}
                                        contaId={selectedContaId.value}
                                    />
                                )}
                            </React.Fragment>
                        )
                    }
                </Paper>
            </div>
        </React.Fragment>
    );
};

export default DetalhaConta;