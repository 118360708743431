import React from "react";
import YellowButton from "../Buttons/YellowButton";
import Grid from "@mui/material/Grid";
import importIlustration from "../../assets/images/import_planilha.png";


const ImportFileLastStep = ({fecharImportar}) => {
    return (
        <React.Fragment>
            <Grid container xs={12} sx={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: 5}}>
                <Grid item xs={6} sx={{display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center"}}>
                    <img src={importIlustration}/>
                    <h3>Já estamos incluindo seus dados no VXMF!</h3>
                    <span style={{fontSize: "14px", lineHeight: "20px"}}>
                        Enquanto realizamos a importação, a plataforma poderá ser utilizada normalmente. Assim que finalizar iremos te avisar, em seguida esses lançamentos poderão ser consultados através do seu financeiro.
                    </span>
                </Grid>
                <Grid item>
                    <YellowButton title="Fechar" onClick={fecharImportar} />
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default ImportFileLastStep;