import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import FilterDayMonthYear from "../Filter/FilterDayMonthYear";
import {
    BLUE_THEME,
    BORDER_TABLE,
    DASH_KPI_NEGATIVE_COLOR,
    DASH_KPI_POSITIVE_COLOR,
    formatterCurrency,
    getCurrentDate,
    GRAY_LABEL_UX,
    styleText,
    WHITE_ESCRITA_THEME, GRAY_LABEL_UX_THEME,
    YELLOW_BG_UX,
} from "../../shared/utils";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import InputSearch from "../Input/InputSearch";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ConciliacaoContaKPI from "../Dash/ConciliacaoContaKPI";
import GrayButton from "../Buttons/GrayButton";
import AddIcon from "@mui/icons-material/Add";
import api from "../../axios";
import {showSnackMessage} from "../../actions/SnackActions";
import BB from "../../containers/Contas/LogoBancos/BB.png";
import Inter from "../../containers/Contas/LogoBancos/Inter.png";


const ConciliacoesConta = (props) => {
    const dispatch = useDispatch();
    const {apelidoConta, vxmfLogo, contaId} = props;

	const currentMonthDate = moment(getCurrentDate()).toDate();
    const [selectedDate, setSelectedDate] = useState(currentMonthDate);
    const [searchValue, setSearchValue] = useState("");
    const [todosKPI, setTodosKPI] = useState(0);
    const [recebidosKPI, setRecebidosKPI] = useState(0);
    const [pagamentosKPI, setPagamentosKPI] = useState(0);
    const [conciliacoesConta, setConciliacoesConta] = useState([]);

    const estabelecimentoId = useSelector(state => state.StoreReducer.estabelecimentoId);
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    useEffect(() => {
        getConciliacoesConta();
    }, [selectedDate, searchValue]);

    const getConciliacoesConta = () => {
        let dataRequest = {
            estabelecimento_id: estabelecimentoId,
            conta_id: contaId,
        };
        if (selectedDate instanceof Date) {
            dataRequest.data_competencia = moment(selectedDate).format("YYYY-MM-DD");
        } else {
            dataRequest.data_competencia = selectedDate;
        }
        if (searchValue !== "") {
            dataRequest.search_value = searchValue;
        }
        api.GetConciliacaoConta(dataRequest).then(response => {
            let data = response.data;

            if (!data) {
                dispatch(showSnackMessage({ message: "Nenhuma conciliação à ser feita manualmente para esta data!", severity: "warning" }));
            } else {
                setConciliacoesConta(data.conciliacao);
                setTodosKPI(data.total_kpi);
                setRecebidosKPI(data.recebidos_kpi);
                setPagamentosKPI(data.pagamentos_kpi);
            }
        });
    };

    const handleConciliar = (extratoId, movimentacaoId) => {
        let dataRequest = {
            estabelecimento_id: estabelecimentoId,
            conta_id: contaId,
            extrato_id: extratoId,
            movimentacao_id: movimentacaoId,
        };
        api.PostConciliarConta(dataRequest).then(() => {
            dispatch(showSnackMessage({ message: "Conciliação realizada com sucesso!", severity: "success" }));
            getConciliacoesConta();
        });

    };

    const handleDayMonthYear = (ano, mes, dia, setState) => {
        setState(`${ano}-${mes}-${dia}`);
    };

    const handleRemoveRow = (idx) => {
        conciliacoesConta.splice(idx, 1);
        setConciliacoesConta([...conciliacoesConta]);
    };

    return (
        <React.Fragment>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item>
                            <FilterDayMonthYear
                                style={{backgroundColor: temaEscuro && BLUE_THEME}}
                                value={selectedDate}
                                setState={setSelectedDate}
                                label={"Dia do mês"}
                                iconPosition={"end"}
                                size={"small"}
                                handleSubmit={handleDayMonthYear}
                                disableMinDate
                            />
                        </Grid>
                        <Grid item>
                            <InputSearch
                                callFunction
                                functionProps={getConciliacoesConta}
                                setSearchValue={setSearchValue}
                                searchValue={searchValue}
                                label={"Pesquisar"}
                            />
                        </Grid>
                        <Grid item sx={{display: "flex", alignItems: "center", cursor: "pointer"}}>
                            <DeleteForeverIcon sx={{...styleText, color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX}}/>
                            <a style={{...styleText, color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX}} onClick={() => setSearchValue("")}>Limpar filtros</a>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={4}>
                        <Grid item xs={4}>
                            <ConciliacaoContaKPI
                                title={"Todos"}
                                data={todosKPI}
                                selectedKPI
                                color={YELLOW_BG_UX}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <ConciliacaoContaKPI
                                title={"Recebidos"}
                                data={recebidosKPI}
                                color={DASH_KPI_POSITIVE_COLOR}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <ConciliacaoContaKPI
                                title={"Pagamentos"}
                                data={pagamentosKPI}
                                color={DASH_KPI_NEGATIVE_COLOR}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                {
                    conciliacoesConta.map((conciliacao, index) => {
                        return (
                            <React.Fragment key={index}>
                                <Grid item xs={5}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sx={{mb: 3}}>
                                            <Grid container spacing={2} sx={{display: "flex", justifyContent: "center"}}>
                                                <Grid item>
                                                    <img src={conciliacao.movimentacao.nome_banco === "BB" ? BB : Inter} />
                                                </Grid>
                                                <Grid item>
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <a style={{fontSize: "18px", fontWeight: "bold"}}>Lançamentos importados</a>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <a style={{fontSize: "12px"}}>{apelidoConta}</a>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>
                                            <Grid container sx={{
                                                backgroundColor : temaEscuro ? BLUE_THEME : GRAY_LABEL_UX_THEME,
                                                backgroundImage: temaEscuro && "none",
                                                p: 3, width: "75%"
                                            }}
                                            >
                                                <Grid item xs={6}>
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <a style={{fontSize: "18px", fontWeight: "bold"}}>{conciliacao.extrato.data_lancamento}</a>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <a style={{fontSize: "12px"}}>Segunda-feira</a>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={6} sx={{display: "flex", justifyContent: "end"}}>
                                                    <a>{formatterCurrency(conciliacao.extrato.valor_lancamento)}</a>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <a>{conciliacao.extrato.descricao_historico}</a>
                                                </Grid>
                                                <Grid item xs={12} sx={{display: "flex", justifyContent: "end", alignItems: "end"}}>
                                                    <a style={{textDecoration: "underline"}} onClick={() => handleRemoveRow(conciliacao.id)}>Ignorar</a>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={2} sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                    <GrayButton
                                        title={"Conciliar"}
                                        onClick={() => handleConciliar(conciliacao.extrato.id, conciliacao.movimentacao.id)}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2} sx={{display: "flex", justifyContent: "center"}}>
                                                <Grid item>
                                                    <img src={vxmfLogo} />
                                                </Grid>
                                                <Grid item>
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <a style={{fontSize: "18px", fontWeight: "bold"}}>Lançamentos a cadastrar</a>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <a style={{fontSize: "12px"}}>Voxel - Menu Financeiro</a>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>
                                            <Grid container sx={{
                                                backgroundColor : temaEscuro ? BLUE_THEME : GRAY_LABEL_UX_THEME,
                                                backgroundImage: temaEscuro && "none",
                                                p: 3, width: "75%",
                                            }}>
                                                <Grid item xs={12}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12}>
                                                            <a style={{fontSize: "18px", fontWeight: "bold"}}>Lançamento sugerido</a>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <a style={{fontSize: "12px"}}>
                                                                Compare os dados antes de conciliar. Clique em Desvincular se a sugestão não corresponder ao lançamento correto.
                                                            </a>
                                                        </Grid>
                                                        <Grid item xs={12} sx={{
                                                            border: 0,
                                                            borderBottom: temaEscuro ? `1px solid ${BORDER_TABLE}` : `1px solid ${WHITE_ESCRITA_THEME}`,
                                                            ml: 2,
                                                        }}/>
                                                        <Grid item xs={6}>
                                                            <Grid container>
                                                                <Grid item xs={12}>
                                                                    <a style={{fontSize: "18px", fontWeight: "bold"}}>
                                                                        {conciliacao.movimentacao.data_movimentacao}
                                                                    </a>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <a style={{fontSize: "12px"}}>Segunda-feira</a>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={6} sx={{display: "flex", justifyContent: "end"}}>
                                                            <a>{formatterCurrency(conciliacao.movimentacao.valor_total)}</a>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Grid container>
                                                                <Grid item xs={12}>
                                                                    <a style={{fontSize: "18px", fontWeight: "bold", color: YELLOW_BG_UX}}>
                                                                        Compra de produto: {conciliacao.movimentacao.codigo_referencia}
                                                                    </a>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <a style={{fontSize: "12px"}}>Categoria: {conciliacao.movimentacao.nome_categoria}</a>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <a style={{fontSize: "12px"}}>Fornecedor: {conciliacao.movimentacao.nome_fornecedor}</a>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Grid container spacing={1}>
                                                                        <Grid item>
                                                                            <a style={{fontSize: "12px"}}>
                                                                                Descontos: {formatterCurrency(conciliacao.movimentacao.desconto)}
                                                                            </a>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={12} sx={{
                                                            border: 0,
                                                            borderBottom: temaEscuro ? `1px solid ${BORDER_TABLE}` : `1px solid ${WHITE_ESCRITA_THEME}`,
                                                            ml: 2,
                                                        }}/>
                                                        <Grid item xs={12}>
                                                            <Grid container spacing={2} sx={{display: "flex", justifyContent: "end"}}>
                                                                <Grid item>
                                                                    <GrayButton
                                                                        title={"Editar"}
                                                                        startIcon={<AddIcon/>}
                                                                    />
                                                                </Grid>
                                                                <Grid item>
                                                                    <GrayButton
                                                                        title={"Desvincular"}
                                                                        startIcon={<AddIcon/>}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        );
                    })

                }

            </Grid>
        </React.Fragment>
    );
};

export default ConciliacoesConta;