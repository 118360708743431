import React from "react";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import "./GenericModal.css";
import {
    BLUE_THEME, smallPaperStyle,
    paperStyleMobile, modalTextTitle, modalTextSubTitle, GRAY_LABEL_UX, WHITE_TABLE, BORDER_TABLE, WHITE_ESCRITA_THEME, BORDER_PAPER
} from "../../shared/utils";
import GrayButton from "../Buttons/GrayButton";
import YellowButton from "../Buttons/YellowButton";
import {useSelector, useDispatch} from "react-redux";
import WarningIcon from "@mui/icons-material/Warning";
import api from "../../axios";
import {showSnackMessage} from "../../actions/SnackActions";
import Divider from "@mui/material/Divider";

const ResetBoletoModal = (props) => {
	const {
        selectedTab,
        boletos,
        openModal,
        setOpenModal,
        getBoletos
    } = props;
    
    const dispatch = useDispatch();
    
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const isDesktop = useSelector(state => state.AuthReducer.isDesktop);

    const resetBoletoStatus = () => {
        const dataRequest = {
            id: boletos,
            status: selectedTab
        };

        api.ResetBoletosStatus(dataRequest).then(() => {
            getBoletos(selectedTab);
            dispatch(showSnackMessage({message: "Status do(s) boleto(s) resetado(s) com sucesso."}));
            setOpenModal(false);
        }).catch(() => {
            dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde.", severity: "error"}));
            setOpenModal(false);
        });
    };

	return(
		<React.Fragment>
			<Modal
				open={openModal}
				onClose={() => setOpenModal(false)}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
                data-testid="despesas_historic-modal"
			>
				<Paper style={{...(isDesktop ? smallPaperStyle : paperStyleMobile), backgroundColor: temaEscuro && BLUE_THEME}}>
                    <Grid container sx={{display: "flex", gap: 2}}>
                        <Grid item xs={12} sx={{...modalTextTitle, paddingTop: 2, color: temaEscuro ? WHITE_TABLE : GRAY_LABEL_UX, display: "flex", alignItems: "center", gap: 1}}>
                            <WarningIcon/>
                            AVISO
                        </Grid>
                        <Grid item xs={12} sx={{marginBottom: 2}}>
                            <Divider sx={{backgroundColor: temaEscuro ? BORDER_TABLE : BORDER_PAPER}} /> 
                        </Grid>
                        <Grid item xs={12} sx={{...modalTextSubTitle, color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX}}>
                            Os status dos boletos selecionados serão restaurados.
                        </Grid>
                        <Grid item xs={12} sx={{...modalTextSubTitle, color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX}}>
                            Deseja continuar?
                        </Grid>
                    </Grid>
                    <Grid container sx={{display: "flex", justifyContent: "end", gap: 2, marginTop: 5}}>
                        <Grid item>
                            <GrayButton
                                onClick={() => setOpenModal(false)}
                                title="Cancelar"/>
                        </Grid>
                        <Grid item >
                            <YellowButton
                                onClick={resetBoletoStatus}
                                testId="reset-boletos-btn"
                                title="Confirmar"
                            />
                        </Grid>
                    </Grid>
				</Paper>
			</Modal>
		</React.Fragment>
	);
};

export default ResetBoletoModal;