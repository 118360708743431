import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Popover from "@mui/material/Popover";
import Grid from "@mui/material/Grid";
import {
  textCompleteStyleBlack,
  textCompleteStyle,
  BLUE_THEME,
  BORDER_BUTTON,
} from "../../shared/utils";
import { useSelector } from "react-redux";

const FilterPriceRange = ({
  inputValorMin,
  setInputValorMin,
  inputValorMax,
  setInputValorMax,
  width,
  label,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [inputLayout, setInputLayout] = useState("R$ 0,00 a R$ 0,00");

  const temaEscuro = useSelector((state) => state.AuthReducer.temaEscuro);
  const open = Boolean(anchorEl);

  const formatToCurrency = (value) => {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);
  };

  useEffect(() => {
    setInputLayout(
      `${inputValorMin ? formatToCurrency(inputValorMin) : "R$ 0,00"} a ${
        inputValorMax ? formatToCurrency(inputValorMax) : "R$ 0,00"
      }`
    );
  }, [inputValorMin, inputValorMax]);

  return (
    <React.Fragment>
      <Grid
        container
        sx={{ display: "flex", alignItems: "center", width: width }}
        spacing={2}
      >
        <Grid item xs>
          <TextField
            size="small"
            onClick={(e) => setAnchorEl(e.currentTarget)}
            value={inputLayout}
            label={label}
            slotProps={{ input: { readOnly: true } }}
            sx={temaEscuro ? textCompleteStyleBlack : textCompleteStyle}
            InputLabelProps={{
              style: {
                color: temaEscuro && BORDER_BUTTON,
              },
            }}
            style={{ backgroundColor: temaEscuro && BLUE_THEME }}
          />

          <Popover
            id="valor-em-aberto"
            open={open}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Grid container spacing={2} sx={{ display: "flex", padding: 2 }}>
              <Grid item xs>
                <TextField
                  type="number"
                  size="small"
                  label="Valor mínimo"
                  value={inputValorMin}
                  onChange={(e) => setInputValorMin(e.target.value)}
                  style={{ backgroundColor: temaEscuro && BLUE_THEME }}
                  sx={{ width: "170px" }}
                  InputLabelProps={{
                    style: {
                      color:
                        temaEscuro && inputValorMin === "" && BORDER_BUTTON,
                    },
                  }}
                />
              </Grid>
              <Grid item xs>
                <TextField
                  type="number"
                  size="small"
                  label="Valor máximo"
                  value={inputValorMax}
                  onChange={(e) => setInputValorMax(e.target.value)}
                  style={{ backgroundColor: temaEscuro && BLUE_THEME }}
                  sx={{ width: "170px" }}
                  InputLabelProps={{
                    style: {
                      color:
                        temaEscuro && inputValorMax === "" && BORDER_BUTTON,
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Popover>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default React.memo(FilterPriceRange);
