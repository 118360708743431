import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import { BLUE_THEME, GRAY_BG_UX, BORDER_BUTTON } from "../../shared/utils";

const InputSearch = (props) => {
    const { functionProps, searchValue, setSearchValue, inputError, EXM } = props;
    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const [inputValue, setInputValue] = useState(searchValue);

    useEffect(() => {
        setInputValue(searchValue);
    }, [searchValue]);

    useEffect(() => {
        if (EXM) {
            setSearchValue(inputValue);
        }
    }, [inputValue]);

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            functionProps();
        }
    };

    return (
        <Box>
            <TextField
                label={props?.label || "Pesquisar"}
                id="input-with-icon-textfield"
                className={props.className}
                variant="outlined"
                disabled={props.disabled}
                value={inputValue}
                onChange={(event) => {
                    setInputValue(event.target.value);
                }}
                data-testid="input-search"
                onBlur={() => setSearchValue(inputValue)}
                sx={{backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX, width: props?.width || "250px", padding: props?.padding }}
                size="small"
                error={inputError}
                onKeyDown={handleKeyDown}
                InputProps={props?.callFunction && ({
                    endAdornment: (
                        <InputAdornment sx={{cursor: "pointer"}} position="end">
                            <SearchIcon onClick={() => functionProps()} />
                        </InputAdornment>
                    ),
                })}
                InputLabelProps={{
                    style: { color: temaEscuro && BORDER_BUTTON, fontSize: "15px" }, 
                }}
            />
        </Box>
    );
};

export default InputSearch;