import React from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import {
    BLUE_THEME,
    WHITE_ESCRITA_THEME,
    BORDER_BUTTON,
    GRAY_BG_UX,
    GRAY_LABEL_UX,
    styleText
} from "../../shared/utils";
import MenuItem from "@mui/material/MenuItem";
import {useSelector} from "react-redux";
import InputsApelidoTipo from "./InputsApelidoTipo";
import Grid from "@mui/material/Grid";


const FormCartaoCredito = (props) => {

    const {
        contaDetails,
        tipoConta,
        isBancoEdit,
        cartaoEmissores,
        inputCartaoEmissor,
        setInputCartaoEmissor,
        inputCartaoContaPagamento,
        setInputCartaoContaPagamento,
        contasPagamento,
        inputCartaoBandeira,
        setInputCartaoBandeira,
        bandeiras,
        inputUltimosDigitos,
        setInputUltimosDigitos,
        inputDiaVencimento,
        setInputDiaVencimento,
        inputDiaFechamento,
        setInputDiaFechamento,
        styleTextField
    } = props;

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    return (
        <React.Fragment>
            <InputsApelidoTipo
                tipoConta={tipoConta}
                contaDetails={contaDetails}
                disabledTipo
                disabledApelido
            />
            <FormControl size="small" sx={{width: "250px"}}>
                <InputLabel id="demo-simple-select-label" sx={{ color: temaEscuro && BORDER_BUTTON, fontSize: "15px" }}>Emissor</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={inputCartaoEmissor}
                    label="Emissor"
                    defaultValue={contaDetails.cartao_emissor}
                    disabled={isBancoEdit}
                    sx={{
                        backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX,
                        width: "250px",
                        "& .MuiInputBase-input": {
                            color: temaEscuro && WHITE_ESCRITA_THEME,
                        },
                    }}
                    onChange={(e) => setInputCartaoEmissor(e.target.value)}
                >
                        {cartaoEmissores.map((emissor) => {
                        return (
                            <MenuItem data-testid="menu-item" key={emissor} value={emissor}>
                                {emissor}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
            <FormControl size="small" sx={{width: "250px"}}>
                <InputLabel id="demo-simple-select-label" sx={{ color: temaEscuro && BORDER_BUTTON, fontSize: "15px" }}>Conta padrão para o pagamento</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={inputCartaoContaPagamento}
                    label="Conta padrão para o pagamento"
                    defaultValue={contaDetails.cartao_conta_pagamento}
                    disabled={isBancoEdit}
                    sx={{
                        backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX,
                        width: "250px",
                        "& .MuiInputBase-input": {
                            color: temaEscuro && WHITE_ESCRITA_THEME,
                        },
                    }}
                    onChange={(e) => setInputCartaoContaPagamento(e.target.value)}
                >
                        {contasPagamento.map((contaP) => {
                        return (
                            <MenuItem data-testid="menu-item" key={contaP} value={contaP}>
                                {contaP}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
            <FormControl size="small" sx={{width: "250px"}}>
                <InputLabel id="demo-simple-select-label" sx={{ color: temaEscuro && BORDER_BUTTON, fontSize: "15px" }}>Bandeira</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={inputCartaoBandeira}
                    label="Bandeira"
                    defaultValue={contaDetails.cartao_bandeira}
                    disabled={isBancoEdit}
                    sx={{
                        backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX,
                        width: "250px",
                        "& .MuiInputBase-input": {
                            color: temaEscuro && WHITE_ESCRITA_THEME,
                        },
                    }}
                    onChange={(e) => setInputCartaoBandeira(e.target.value)}
                >
                        {bandeiras.map((bandeira) => {
                        return (
                            <MenuItem data-testid="menu-item" key={bandeira} value={bandeira}>
                                {bandeira}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
            <TextField
                label="Últimos 4 números do cartão"
                id="outlined-size-small"
                defaultValue={contaDetails.cartao_ultimos_digitos}
                size="small"
                value={inputUltimosDigitos}
                onChange={(event) => {
                    setInputUltimosDigitos(event.target.value);
                }}
                sx={{...styleTextField}}
                InputLabelProps={{
                    style: { color: temaEscuro && BORDER_BUTTON, fontSize: "15px" }, 
                }}
                inputProps={{ maxLength: 4 }}
            />
            <Grid container sx={{display: "flex", gap: 4, marginTop: 2}}>
                <Grid item xs={12} sx={{display: "flex", alignItems: "start", flexDirection: "column", gap: 0.5}}>
                    <span style={{...styleText, color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX}}>Saldo Inicial</span>
                </Grid>
                <Grid item xs={12} sx={{display: "flex", alignItems: "start", justfiyContent: "center", flexDirection: "row", gap: 2}}>
                    <TextField
                        label="Dia do Fechamento"
                        id="outlined-size-small"
                        defaultValue={contaDetails.cartao_dia_fechamento}
                        size="small"
                        value={inputDiaFechamento}
                        onChange={(event) => {
                            setInputDiaFechamento(event.target.value);
                        }}
                        sx={{...styleTextField}}
                        InputLabelProps={{
                            style: { color: temaEscuro && BORDER_BUTTON, fontSize: "15px" }, 
                        }}
                        inputProps={{ maxLength: 2 }}
                    />
                    <TextField
                        label="Dia do Fechamento"
                        id="outlined-size-small"
                        defaultValue={contaDetails.cartao_dia_fechamento}
                        size="small"
                        value={inputDiaVencimento}
                        onChange={(event) => {
                            setInputDiaVencimento(event.target.value);
                        }}
                        sx={{...styleTextField}}
                        InputLabelProps={{
                            style: { color: temaEscuro && BORDER_BUTTON, fontSize: "15px" }, 
                        }}
                        inputProps={{ maxLength: 2 }}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default React.memo(FormCartaoCredito);