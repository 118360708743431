import React, {useState, useEffect} from "react";
import {
    WHITE_ESCRITA_THEME,
    GRAY_LABEL_UX,
    styleText
} from "../../shared/utils";
import {useSelector} from "react-redux";
import FilterDayMonthYear from "../Filter/FilterDayMonthYear";
import Grid from "@mui/material/Grid";
import InputsApelidoTipo from "./InputsApelidoTipo";
import moment from "moment";
import InputCurrency from "../Input/InputCurrency";

const FormCaixinha = (props) => {

    const {
        inputTipoConta,
        setInputTipoConta,
        contaDetails,
        tipoConta,
        inputApelido,
        setInputApelido,
        isBancoEdit,
        handleDayMonthYear,
        inputSaldoFinal,
        setInputSaldoFinal,
        selectedDate

    } = props;

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    const [lastDate, setLastDate] = useState("");

    const getLastDate = (date) => {
        const [day, month, year] = date.split("/");
        const lastDate = new Date(`${year}-${month}-${day}`);

        lastDate.setDate(lastDate.getDate() - 1);

        setLastDate(lastDate.toLocaleDateString("pt-BR"));
    };

    useEffect(() => {
        if (contaDetails.data_competencia !== selectedDate && selectedDate !== null){
            getLastDate(selectedDate);
        } else if (selectedDate === null) {
            getLastDate(contaDetails.data_competencia);
        }
    }, [contaDetails.data_competencia, selectedDate]);

    return (
        <React.Fragment>
            <InputsApelidoTipo
                inputTipoConta={inputTipoConta}
                isBancoEdit={isBancoEdit}
                contaDetails={contaDetails}
                setInputTipoConta={setInputTipoConta}
                tipoConta={tipoConta}
                inputApelido={inputApelido}
                setInputApelido={setInputApelido}
            />
            <Grid container sx={{display: "flex", gap: 4, marginTop: 2}}>
                <Grid item xs={12} sx={{display: "flex", alignItems: "start", flexDirection: "column", gap: 0.5}}>
                    <span style={{...styleText, color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX}}>Saldo Inicial</span>
                </Grid>
                <Grid item xs={12} sx={{display: "flex", alignItems: "start", justfiyContent: "center", flexDirection: "row", gap: 2}}>
                    <FilterDayMonthYear
                        handleSubmit={handleDayMonthYear}
                        value={moment(contaDetails.data_competencia, "YYYY-MM-DD").toDate()}
                        label="Data do saldo"
                        width="250px"
                        isEditConta
                    />
                    <InputCurrency valor={inputSaldoFinal} setValor={setInputSaldoFinal} title={`Saldo final da conta em ${lastDate}`} />
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default React.memo(FormCaixinha);