import React, {useState} from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Grid from "@mui/material/Grid";
import {
	GRAY_LABEL_UX_THEME,
	GRAY_LABEL_UX,
	YELLOW_BG_UX,
    BLACK_TABLE,
    BLUE_THEME,
    paperMobileStyle,
    LINE_TABLE,
    WALPAPPER_THEME,
    formatarValorBrasileiro,
    GRAY_BORDER_UX,
    WHITE_SUBITEM_NAVBAR
} from "../../shared/utils";
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";
// import Header from "../../components/Titles/Header";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import GrayButton from "../../components/Buttons/GrayButton";
import YellowButton from "../../components/Buttons/YellowButton";
import BoletosModal from "../../components/Modal/BoletosModal";
import ResetBoletoModal from "../../components/Modal/ResetBoletoModal";

const BoletosMobile = (props) => {

    const {selectedTab, handleChange, data, getBoletos, itensSelecionados, setItensSelecionados} = props;

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    const isOperator = useSelector(state => state.AuthReducer.isOperator);
    const isAdmin = useSelector(state => state.AuthReducer.isAdmin);
    const [openModalReset, setOpenModalReset] = useState(false);
    const [boletoStatus, setBoletoStatus] = useState("");
    const [openModal, setOpenModal] = useState(false);

    const handleOpenModalBoleto = (boletoStatus) => {
        setBoletoStatus(boletoStatus);
        setOpenModal(true);
    };

    // const ColoredHeader = ({ title }) => (
    //     <Header title={<span style={{ color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_STRONG_UX, margin: "auto" }}>{title}</span>} />
    // );

    const handleChangeCardSelected = (item) => {
        handleChangeCheckbox(item);
    };

    const handleChangeCheckbox = (item) => {
        const isItemSelected = itensSelecionados.includes(item.id);
        // verifica se o item está selecionado
        if (isItemSelected) {
            setItensSelecionados(prevState => prevState.filter(id => id !== item.id));
        } else {
            setItensSelecionados(prevState => [...prevState, item.id]);
        }
    };

    const CardDepesas = ({index, item}) => {
        return (
            <React.Fragment>
                <BoletosModal
                    selectedTab={selectedTab}
                    getBoletos={getBoletos}
                    boletoStatus={boletoStatus}
                    boletos={itensSelecionados}
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                />
                <ResetBoletoModal
                    selectedTab={selectedTab}
                    boletos={itensSelecionados}
                    openModal={openModalReset}
                    setOpenModal={setOpenModalReset}
                    getBoletos={getBoletos}
                />
                <Card key={index} sx={{backgroundColor: temaEscuro ? WALPAPPER_THEME : LINE_TABLE, ...styles.card, border: temaEscuro ? undefined : `1.5px solid ${GRAY_BORDER_UX}`}} data-testid="despesas-card">
                    <Grid container sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                        <Grid item sx={{display: "flex", justifyContent: "center", gap: 2, alignItems: "center"}}>
                            <CardActions sx={{padding: 0}}>
                                <Checkbox 
                                    sx={{padding: 0, margin: 0}} 
                                    checked={itensSelecionados.includes(item.id)} 
                                    onChange={() => handleChangeCardSelected(item)}
                                    inputProps={{ "aria-label": "controlled", "data-testid": "despesas-card-checkbox" }}
                                />
                            </CardActions>
                            <Typography style={{...styles.title}}>
                                {formatarValorBrasileiro(item.valor)}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography style={{...styles.title}}>
                                {item.data_vencimento}
                            </Typography>
                        </Grid>
                    </Grid>
                    <CardContent sx={{padding: 0, "&.MuiCardContent-root:last-child": {paddingBottom: 0}}}>
                        <Grid container>
                            <Grid item sx={{marginTop: 1}}>
                                <Typography style={{...styles.text}}>
                                    {item.cnpj_beneficiario}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sx={{marginTop: 1}}>
                                <Grid container sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                    <Grid item>
                                        <Typography style={{...styles.text}}>
                                            Benef: {item.beneficiario}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography style={{...styles.text}}>
                                            Pag: {item.pagador}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </React.Fragment>
        );
    };

    return (
        <div className="main-mobile" data-testid="despesas-mobile">
            <Paper sx={{...paperMobileStyle, backgroundColor: temaEscuro ? BLUE_THEME : LINE_TABLE, backgroundImage: temaEscuro && "none"}}>
                <Grid container xs={12} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <Grid item xs={12} sx={{paddingTop: 2, paddingBottom: 2}}>
                        {/* <ColoredHeader title="Despesas" /> */}
                    </Grid>
                    <Grid item xs={12}>
                        <Tabs
                            value={selectedTab}
                            onChange={handleChange}
                            TabIndicatorProps={{style: {backgroundColor: "transparent"}}}
                            className="buttonTabs"
                            centered={true}
                            sx={{
                                "& .MuiTab-root": {padding: 1.4, margin: 0, minWidth: "auto", minHeight: "auto"},
                                "& .MuiTabs-flexContainer": {flexWrap: "wrap"},
                            }}
                        >
                            <Tab
                                value={"Pendente"}
                                label="Pendentes"
                                sx={{
                                    borderRadius: "8px",
                                    fontSize: "11px",
                                    padding: 0,
                                    color: temaEscuro ? GRAY_LABEL_UX_THEME : BLACK_TABLE,
                                    backgroundColor: selectedTab === "Pendente" ? YELLOW_BG_UX : "transparent",
                                    "&.Mui-selected": {
                                        color: GRAY_LABEL_UX,
                                    },
                                }}
                                data-testid="tab-pendente"
                            />
                            <Tab 
                                value={"Aceito"}
                                label="Aceitos"
                                sx={{
                                    borderRadius: "8px",
                                    fontSize: "11px",
                                    color: temaEscuro ? GRAY_LABEL_UX_THEME : BLACK_TABLE,
                                    backgroundColor: selectedTab === "Aceito" ? YELLOW_BG_UX : "transparent",
                                    "&.Mui-selected": {
                                        color: GRAY_LABEL_UX,
                                    },
                                }}
                                data-testid="tab-aceito"
                            />
                            <Tab 
                                value={"Cancelado"}
                                label="Recusados"
                                sx={{
                                    borderRadius: "8px",
                                    fontSize: "11px",
                                    color: temaEscuro ? GRAY_LABEL_UX_THEME : BLACK_TABLE,
                                    backgroundColor: selectedTab === "Cancelado" ? YELLOW_BG_UX : "transparent",
                                    "&.Mui-selected": {
                                        color: GRAY_LABEL_UX,
                                    },
                                }}
                                data-testid="tab-recusado"
                            />
                            <Tab
                                value={"Ignorado"}
                                label="Ignorados"
                                sx={{
                                    borderRadius: "8px",
                                    fontSize: "11px",
                                    color: temaEscuro ? GRAY_LABEL_UX_THEME : BLACK_TABLE,
                                    backgroundColor: selectedTab === "Ignorado" ? YELLOW_BG_UX : "transparent",
                                    "&.Mui-selected": {
                                        color: GRAY_LABEL_UX,
                                    },
                                }}
                                data-testid="tab-ignorado"
                            />
                        </Tabs>
                    </Grid>
                    <Grid item xs={12} sx={{...styles.grid_card}}>
                        {data.map((item, index) => (
                            <CardDepesas key={index} item={item}/>
                        ))}
                    </Grid>
                    {
                        Object.keys(itensSelecionados).length > 0  && (
                            <Grid 
                                item 
                                sx={{display: "flex", alignItems: "center", justifyContent: "center", gap: 2, marginTop: 2, position: "fixed", bottom: 0, width: "100%", padding: 1.5, backgroundColor: temaEscuro ? BLUE_THEME : WHITE_SUBITEM_NAVBAR, flexWrap: "wrap"}} 
                                xs={12} 
                                data-testid="datagrid-num-lines"
                            >
                                {
                                    (selectedTab == "Aceito" || selectedTab == "Cancelado") && (
                                        <GrayButton
                                            title={"Reverter"}
                                            onClick={() => setOpenModalReset(true)}
                                            className="button-mobile"
                                        />
                                    )
                                }
                                {
                                    (isOperator || isAdmin) && (
                                        <GrayButton
                                            title={"Ignorar"}
                                            onClick={() => handleOpenModalBoleto("Ignorado")}
                                            className="button-mobile"
                                        />
                                    )
                                }
                                <GrayButton
                                    title={"Recusar"}
                                    onClick={() => handleOpenModalBoleto("Cancelado")}
                                    className="button-mobile"
                                />
                                <YellowButton
                                    title={"Aceitar"}
                                    onClick={() => handleOpenModalBoleto("Aceito")}
                                />
                            </Grid>
                        )
                    }
                </Grid>
            </Paper>
        </div>
    );
};

export default BoletosMobile;

const styles = {
    title: {
        fontSize: 18,
        fontWeight: 600,
    },
    text: {
        fontSize: 14,
        fontWeight: 400,
    },
    card: {
        padding: 2, 
        borderRadius: 2, 
        minHeight: "85px",
        boxShadow: "none"
    },
    grid_card: {
        marginTop: 1,
        display: "flex",
        flexDirection: "column",
        gap: 1,
        overflowY: "auto",
        maxHeight: "350px"
    }
};
