import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {GRAY_STRONG_UX, TITLE_SIZE, TITLE_MARGIN, WHITE_ESCRITA_THEME, BLUE_THEME, LINE_TABLE, avatarStyle, BORDER_BLACK_THEME_CARD,BORDER_PAPER, PURPLE_PATH_UX} from "../../shared/utils";
import Grid from "@mui/material/Grid";
import {Tooltip} from "@mui/material";
import {Link, useLocation} from "react-router-dom";
import ListItemButton from "@mui/material/ListItemButton";
import Avatar from "@mui/material/Avatar";
import LogoutIcon from "@mui/icons-material/Logout";
import api from "../../axios";
import {
	logoutUser,
} from "../../actions/AuthActions";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Badge from "@mui/material/Badge";
import {useNavigate} from "react-router-dom";

const Header = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
	const isDesktop = useSelector(state => state.AuthReducer.isDesktop);
    const email = useSelector(state => state.AuthReducer.email);
    const imagePerfil = useSelector(state => state.AuthReducer.imagePerfil);
    const name = useSelector(state => state.AuthReducer.name);
    const notifications = useSelector(state => state.AuthReducer.notifications);

    const [showItens, setShowItens] = useState(true);

    useEffect(() => {
        let path = location.pathname.replace("/", "");
        path === "perfil" ? setShowItens(false) : setShowItens(true);
    }, []);

    const initals = () => {
        if (name !== null) {
            let nameSplit = name.split(" ").map((word) => word[0]).join("");
            return nameSplit;
        }
	};

    const logout = () =>{
		api.Logout().then(() => {
			dispatch(logoutUser());
			window.location.reload();
		}).catch(async () => {
			dispatch(logoutUser());
			window.location.reload();
		});
	};

    const goNotifications = () => {
        navigate("/perfil", { state: {selectedTab: 2}});
    };

    return (
        <Grid 
            container 
            sx={{
                backgroundColor: temaEscuro ? BLUE_THEME : LINE_TABLE,
                padding: "10px 10px 10px 20px",
                borderRadius: "25px 100px 100px 25px",
                marginBottom: `${TITLE_MARGIN}em`,
                display: "flex",
                justifyContent: "space-between",
                border: temaEscuro ? `1px solid ${BORDER_BLACK_THEME_CARD}` : `1px solid ${BORDER_PAPER}`,
            }}
            data-testid={props.dataTestId}
        >
            <Grid item>
                {
                    (props.subTitle && props.previousPage) ? (
                        <Grid container sx={{display: "flex", gap: 1, justifyContent: "center", alignItems: "center", marginBlock: "10px",}}>
                            <Grid item sx={{color: PURPLE_PATH_UX, fontSize: "20px", fontWeight: "500"}}>
                                <Link to={props.previousPage} className="text-link">
                                    {props.subTitle}
                                </Link>
                            </Grid>
                            <Grid item>
                                <span>/</span>
                            </Grid>
                            <Grid 
                                item 
                                sx={{
                                    color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_STRONG_UX,
                                    fontSize: TITLE_SIZE,
                                    fontWeight: "bold",
                                    lineHeight: 1
                                }}
                            >
                                <span>{props.title}</span>
                            </Grid>
                        </Grid>
                    ) : (
                        <h1
                            style={{
                                color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_STRONG_UX,
                                fontSize: TITLE_SIZE,
                                fontWeight: "bold",
                                lineHeight: 1,
                                marginBlock: "10px",
                                textAlign: isDesktop ? "left" : "center",
                            }}
                            data-testid="header-title"
                            className={props.className}
                        >
                            {props.title}
                        </h1>
                    )
                }
            </Grid>
            <Grid item sx={{display: "flex", flexDirection: "row", gap: 2, alignItems: "center"}}>
                <Grid container>
                    <Grid item sx={{display: "flex", alignItems: "center", justifyContent: "center", gap: 3}}>
                        {
                            showItens && (
                                <Badge 
                                    badgeContent={notifications} 
                                    color="primary" 
                                    overlap="circular" 
                                    sx={{
                                        "& .MuiBadge-badge": {
                                            fontWeight: 600, fontSize: "15.5px"
                                    }
                                }}>
                                    <NotificationsIcon sx={{cursor: "pointer", fontSize: "30px"}} onClick={() => goNotifications()} />
                                </Badge>
                            )
                        }
                        <LogoutIcon onClick={() => logout()} sx={{cursor: "pointer", fontSize: "25px"}} />
                    </Grid>
                </Grid>
                <Tooltip title={email}>
                    <Link to={showItens ? "/perfil" : ""} className="text-link" role="item">
                        <ListItemButton style={{...styles.perfil}}>
                            <Grid container>
                                <Grid item>
                                    <Avatar sx={avatarStyle}>
                                        {
                                            (imagePerfil !== "" && imagePerfil !== undefined) ?
                                                (
                                                    <img src={imagePerfil} alt="image" style={{maxHeight: "50px", maxWidth: "50px"}}/>
                                                ) : (
                                                    <React.Fragment>
                                                        {initals()}
                                                    </React.Fragment>
                                                )
                                        }
                                    </Avatar>
                                </Grid>
                            </Grid>
                        </ListItemButton>
                    </Link>
                </Tooltip>
            </Grid>
        </Grid>
        
    );
};

export default React.memo(Header);

const styles = {
	perfil: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
        padding: 0,
        height: "100%",
        borderRadius: "50%",
	}
};