import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { styleYellowButton } from "../../shared/utils";

const YellowButton = (props) => {
    const navigate = useNavigate();

    const redirect = () => {
        if (props?.state) {
            navigate(props.path, { state: props.state});
        } else {
            navigate(props.path);
        }
    };

    return (
        <Button
            className={props?.className}
            type={props.type ? props.type : "button"}
            variant="contained"
            data-testid={props?.testId}
            onClick={props?.isRedirect ? redirect : props.onClick}
            style={props?.disabled ? null : styleYellowButton}
            startIcon={props?.startIcon}
            disabled={props?.disabled}
            sx={props?.borderRadius && props.borderRadius}
        >
            {props.title}
        </Button>
    );
};

export default React.memo(YellowButton);