import React, {useState, useEffect} from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import {
    BLUE_THEME,
    WHITE_ESCRITA_THEME,
    BORDER_BUTTON,
    GRAY_BG_UX,
    GRAY_LABEL_UX,
    styleText
} from "../../shared/utils";
import MenuItem from "@mui/material/MenuItem";
import {useSelector} from "react-redux";
import FilterDayMonthYear from "../Filter/FilterDayMonthYear";
import Grid from "@mui/material/Grid";
import InputsApelidoTipo from "./InputsApelidoTipo";
import moment from "moment";
import InputCurrency from "../Input/InputCurrency";


const FormPoupanca = (props) => {

    const {
        contaDetails,
        tipoConta,
        inputApelido,
        setInputApelido,
        handleDayMonthYear,
        inputSaldoInicial,
        setInputSaldoInicial,
        selectedDate,
        inputModalidade,
        setInputModalidade,
        modalidades,
        bancos,
        setInputBanco,
        inputBanco,
        setInputContaVinculada,
        inputContaVinculada,
        contasCorrente
    } = props;

    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);

    const [lastDate, setLastDate] = useState("");

    const getLastDate = (date) => {
        const [day, month, year] = date.split("/");
        const lastDate = new Date(`${year}-${month}-${day}`);

        lastDate.setDate(lastDate.getDate() - 1);

        setLastDate(lastDate.toLocaleDateString("pt-BR"));
    };

    useEffect(() => {
        if (contaDetails.data_competencia !== selectedDate && selectedDate !== null){
            getLastDate(selectedDate);
        } else if (selectedDate === null) {
            getLastDate(contaDetails.data_competencia);
        }
    }, [contaDetails.data_competencia, selectedDate]);

    return (
        <React.Fragment>
            <InputsApelidoTipo
                contaDetails={contaDetails}
                tipoConta={tipoConta}
                inputApelido={inputApelido}
                setInputApelido={setInputApelido}
                disabledTipo
            />
            <FormControl size="small" sx={{width: "250px"}}>
                <InputLabel id="demo-simple-select-label" sx={{ color: temaEscuro && BORDER_BUTTON, fontSize: "15px" }}>Banco</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Banco"
                    value={inputBanco}
                    defaultValue={contaDetails.banco}
                    sx={{
                        backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX,
                        width: "250px",
                        "& .MuiInputBase-input": {
                            color: temaEscuro && WHITE_ESCRITA_THEME,
                        },
                    }}
                    onChange={(e) => setInputBanco(e.target.value)}
                >
                    {bancos.map((banco) => {
                        return (
                            <MenuItem data-testid="menu-item" key={banco} value={banco}>
                                {banco}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
            <FormControl size="small" sx={{width: "250px"}}>
                <InputLabel id="demo-simple-select-label" sx={{ color: temaEscuro && BORDER_BUTTON, fontSize: "15px" }}>Conta Corrente vinculada</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Conta Corrente vinculada"
                    value={inputContaVinculada}
                    defaultValue={contaDetails.cartao_conta_pagamento}
                    sx={{
                        backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX,
                        width: "250px",
                        "& .MuiInputBase-input": {
                            color: temaEscuro && WHITE_ESCRITA_THEME,
                        },
                    }}
                    onChange={(e) => setInputContaVinculada(e.target.value)}
                >
                    {contasCorrente.map((cv) => {
                        return (
                            <MenuItem data-testid="menu-item" key={cv} value={cv}>
                                {cv}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
            <FormControl size="small" sx={{width: "250px"}}>
                <InputLabel id="demo-simple-select-label" sx={{ color: temaEscuro && BORDER_BUTTON, fontSize: "15px" }}>Modalidade</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={inputModalidade}
                    label="Banco"
                    defaultValue={contaDetails.modalidade}
                    sx={{
                        backgroundColor: temaEscuro ? BLUE_THEME : GRAY_BG_UX,
                        width: "250px",
                        "& .MuiInputBase-input": {
                            color: temaEscuro && WHITE_ESCRITA_THEME,
                        },
                    }}
                    onChange={(e) => setInputModalidade(e.target.value)}
                >
                        {modalidades.map((mod) => {
                        return (
                            <MenuItem data-testid="menu-item" key={mod} value={mod}>
                                {mod}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
            <Grid container sx={{display: "flex", gap: 4, marginTop: 2}}>
                <Grid item xs={12} sx={{display: "flex", alignItems: "start", flexDirection: "column", gap: 0.5}}>
                    <span style={{...styleText, color: temaEscuro ? WHITE_ESCRITA_THEME : GRAY_LABEL_UX}}>Saldo Inicial</span>
                </Grid>
                <Grid item xs={12} sx={{display: "flex", alignItems: "start", justfiyContent: "center", flexDirection: "row", gap: 2}}>
                    <FilterDayMonthYear
                        handleSubmit={handleDayMonthYear}
                        value={moment(contaDetails.data_competencia, "YYYY-MM-DD").toDate()}
                        label="Data do saldo"
                        width="250px"
                        isEditConta
                    />
                    <InputCurrency valor={inputSaldoInicial} setValor={setInputSaldoInicial} title={`Saldo inicial da conta em ${lastDate}`} />

                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default React.memo(FormPoupanca);