import React, { useState } from "react";
import mammoth from "mammoth";


const FileViewer = ({fileType, filePath, fileBlob}) => {
    const [html, setHtml] = useState("");
    
    const getDisplay = () => {

        switch (fileType) {
            case "pdf":
                return (
                    <iframe
                        src={filePath}
                        width="100%"
                        height="100%"
                    />
                );
            case "png":
            case "jpg":
            case "jpeg":
                return (
                    <img
                        src={filePath}
                        alt="Imagem"
                        style={{width: "100%", height: "100%"}}
                    />
                );
            case "mp4":
                return (
                    <video
                        width="100%"
                        height="100%"
                        controls
                    >
                        <source src={filePath} type="video/mp4"/>
                    </video>
                );
            case "mp3":
                return (
                    <audio
                        controls
                    >
                        <source src={filePath} type="audio/mpeg"/>
                    </audio>
                );
            case "docx":
                mammoth.convertToHtml({arrayBuffer: fileBlob}).then((result) => {
                    setHtml(result.value);
                });

                return (
                    <div dangerouslySetInnerHTML={{__html: html}}>
                    </div>
                );
            default:                
                return (
                    <div>
                        <span>Vizualização de arquivos {fileType} não é suportada.</span>
                    </div>
                );
        }
    };

    return (
        getDisplay()
    );
};

export default FileViewer;