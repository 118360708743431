import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { useSelector, useDispatch } from "react-redux";
import {
    paperStyle,
    PAPER_PADDING_THEME,
    GRAY_BG_UX,
    BLUE_THEME,
    BORDER_PAPER,
    BORDER_BLACK_THEME_CARD,
	GRAY_PDF
} from "../../shared/utils";
import api from "../../axios";
import Paper from "@mui/material/Paper";
import Header from "../../components/Titles/Header";
import YellowButton from "../../components/Buttons/YellowButton";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { showSnackMessage } from "../../actions/SnackActions";
import CertificadoAlert from "../../components/Alert/CertificadoAlert";

const Certificados = () => {
	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
	const estabelecimentoId = useSelector(state => state.StoreReducer.estabelecimentoId);

	const [certificados, setCertificados] = useState([]);
	const [contasSemCertificado, setContasSemCertificado] = useState([]);

	const nav = useNavigate();

	const dispatch = useDispatch();

	useEffect(() => {
		if (estabelecimentoId !== 0) {
			getCertificados();
			checkContaCertificado();
		}
	}, []);

	const checkContaCertificado = () => {
		const dataRequest = {
			estabelecimento_id: estabelecimentoId
		};

		api.CheckContaCertificado(dataRequest).then((response) => {
			setContasSemCertificado(response.data);
		}).catch(() => {
			dispatch(showSnackMessage({message: "Erro ao buscar contas sem certificados", type: "error"}));
		});
	};

	const getCertificados = () => {
		const dataRequest = {
			estabelecimento_id: estabelecimentoId,
            type: "certificado"
		};

		api.GetCertificados(dataRequest).then((response) => {
			let dataResponse = response.data;
			setCertificados(dataResponse.certificados);
		}).catch(() => {
			dispatch(showSnackMessage({message: "Erro ao buscar certificados", type: "error"}));
		});
	};

	return (
	<div className="main">
		<Header title="Certificados"/>
		{
			contasSemCertificado.length > 0 && (
				<CertificadoAlert
					contasSemCertificado={contasSemCertificado}
				/>
			)
		}
		<Paper data-testid="certificados" sx={{...paperStyle,
			backgroundColor: temaEscuro ? PAPER_PADDING_THEME : GRAY_BG_UX,
			backgroundImage: temaEscuro && "none",
			border: temaEscuro ? `1px solid ${BORDER_BLACK_THEME_CARD}` : `1px solid ${BORDER_PAPER}`
		}}>
			<TableContainer data-testid="certificados-table">
				<Table>
					<TableHead sx={{backgroundColor: temaEscuro ? BLUE_THEME : GRAY_PDF}}>
						<TableRow>
							<TableCell sx={{textAlign: "center", width: "33%"}}>Certificado</TableCell>
							<TableCell sx={{textAlign: "center", width: "33%"}}>Banco</TableCell>
							<TableCell sx={{textAlign: "center", width: "33%"}}>Data de Vencimento</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{
							certificados.map((certificado) => {
								return (
									<TableRow key={certificado.id}>
										<TableCell sx={{textAlign: "center"}}>{certificado.nome}</TableCell>
										<TableCell sx={{textAlign: "center"}}>{certificado.banco}</TableCell>
										<TableCell sx={{textAlign: "center"}}>{certificado.data_expiracao}</TableCell>
									</TableRow>
								);
							})
						}
					</TableBody>
				</Table>
			</TableContainer>
			<Grid container sx={{display: "flex", justifyContent: "center", alignItems: "center", mt: "1.5rem"}}>
				<YellowButton
					testId="button-novo-certificado"
					title="Novo Certificado"
					startIcon={<AddIcon/>}
					onClick={() => nav("/upload_certificado")}
				/>
			</Grid>
		</Paper>
	</div>
	);
};

export default Certificados;