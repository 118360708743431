import React, { useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import GrayButton from "./GrayButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
    BLACK_TABLE_THEME,
} from "../../shared/utils";
import api from "../../axios";
import { showSnackMessage } from "../../actions/SnackActions";
import EdicaoLoteModal from "../Modal/EdicaoLoteModal";


export default function OptionsEXMSelectedItems(props) {

    const estabelecimentoID = useSelector(state => state.StoreReducer.estabelecimentoId);

    const {data, exmItems, getExtratoMovimentacoes, deleteMovimentacoesLote} = props;

    const [isSameType, setIsSameType] = useState(false);
    const [movimentacaoType, setMovimentacaoType] = useState("");
    const [openEdicaoModal, setOpenEdicaoModal] = useState(false);
    
    useEffect(() => {
        let selectedItems = data.filter(item => exmItems.includes(item.id));
        if (selectedItems.length !== 0) {
            setMovimentacaoType(selectedItems[0].tipo_movimentacao);
            let itemTypes = selectedItems.every(item => (item.tipo_movimentacao === selectedItems[0].tipo_movimentacao) && (selectedItems[0].tipo_movimentacao !== "Transferido"));
            setIsSameType(itemTypes);
        }
    }, [exmItems]);

    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const moreVertIcon = useMemo(() => (
		<MoreVertIcon/>
	), []);

    const updateMovimentacaoType = (type) => {
        const dataRequest = {
            selected_movimentacoes: exmItems,
            type: type,
            estabelecimento_id: estabelecimentoID
        };

        api.UpdateTypeMovimentacoes(dataRequest).then(response => {
            getExtratoMovimentacoes();
            dispatch(showSnackMessage({message: response.data, severity: "success"}));
        }).catch(() => {
            dispatch(showSnackMessage({message: "Não foi possível atualizar as movimentações selecionadas.", severity: "error"}));
        });
    };


    const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
    
    return (
        <div>
            <GrayButton
                title={exmItems.length > 1 ? "Ações em lote" : "Ações"}
                startIcon={moreVertIcon}
                onClick={handleClick}
                testId="options-menu"
                className={props.className}
            />
                <EdicaoLoteModal
                    openModal={openEdicaoModal}
                    setOpenModal={setOpenEdicaoModal}
                    exmItems={exmItems}
                    getExtratoMovimentacoes={getExtratoMovimentacoes}
                    movimentacaoType={movimentacaoType}
                    isEXM
                />
                <Menu
                    sx={{
                        "& .css-kc02vp-MuiPaper-root-MuiPopover-paper-MuiMenu-paper" : {
                            background: temaEscuro && BLACK_TABLE_THEME
                        }
                    }}
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={handleCloseMenu}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                    transformOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                >
                    <MenuItem
                        onClick={() => deleteMovimentacoesLote()}
                    >
                        Excluir
                    </MenuItem>
                    {
                        isSameType && (
                            <React.Fragment>
                                <MenuItem
                                    onClick={() => setOpenEdicaoModal(true)}
                                >
                                    Editar
                                </MenuItem>
                                <MenuItem
                                    onClick={() => updateMovimentacaoType("Pago")}
                                >
                                    Marcar como Pago
                                </MenuItem>
                                <MenuItem
                                    onClick={() => updateMovimentacaoType("Em Aberto")}
                                >
                                    Marcar Em Aberto
                                </MenuItem>
                                <MenuItem
                                    onClick={() => updateMovimentacaoType("Agendado")}
                                >
                                    Marcar como Agendado
                                </MenuItem>
                                <MenuItem
                                    onClick={() => updateMovimentacaoType("Não Agendado")}
                                >
                                    Desmarcar como Agendado
                                </MenuItem>
                            </React.Fragment>
                        )
                    }
                </Menu>
        </div>
    );
}